<template>
    <picture-type-list      v-if="activePage === 'list'"      @createPictureType="createPictureType"  @editPictureType="editPictureType" @addPicture="addPicture"   />
    <picture-type-create    v-if="activePage === 'create'"       />
    <picture-type-edit      v-if="activePage === 'edit'"                :editData="this.editData"/>
    <add-picture             v-if="activePage === 'add-picture'"         :editData="this.editData"/>
</template>
<script>
 import pictureTypeList     from  './PictureTypeList';
 import pictureTypeCreate   from  './PictureTypeCreate';
 import pictureTypeEdit     from  './PictureTypeEdit';
 import addPicture          from  './AddPicture';
export default {

    name: "PictureTypeBox",
    components: {
      pictureTypeList,
      pictureTypeCreate,
      pictureTypeEdit,
      addPicture,
    },
    data() {
        return {
            activePage :'',
            editData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        createPictureType(){
            this.activePage = 'create';
        },
        editPictureType(pictureType){
            this.activePage = 'edit';
            this.editData = pictureType;
        },
        addPicture(pictureType){
            this.activePage = 'add-picture';
            this.editData = pictureType;
        }
    },
}
</script>


