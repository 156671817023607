<template>
    
    <event-type-list @createEvent="eventTypeCreate" @editEvent="eventTypeEdit"  v-if="activePage === 'list'" />
    <event-type-create v-if="activePage === 'create'" />
    <event-type-edit   v-if="activePage === 'edit'" :offerId="this.editData"/>
</template>
<script>
 import eventTypeList   from  './EventTypeList';
 import eventTypeCreate   from  './EventTypeCreate';
 import eventTypeEdit   from  './EventTypeEdit';
export default {
   
    name: "EventTypeBox",
    components: {
      eventTypeList,
      eventTypeCreate,
      eventTypeEdit,
    },
    data() {
        return {
            activePage :'',
            editData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        eventTypeEdit(eventType){
            this.activePage = 'edit';
            this.editData = eventType;
        },
        eventTypeCreate(){
            this.activePage = 'create';
        }
    },
}
</script>


