<template>
         <div class="col-6">
            <label for="" class="form-label">{{translation('furniture_transport_to_venue_included')}}</label>
            <div class="form-check">
               <input type="radio" class="form-check-input" id='furnitureTransportToVenueIncludedYes' name="furniture_transport_to_venue_included" value="yes" checked>
               <label class="form-check-label" for="furnitureTransportToVenueIncludedYes">{{translation('yes')}}</label>
            </div>
            <div class="form-check">
               <input type="radio" class="form-check-input" id='furnitureTransportToVenueIncludedNo' name="furniture_transport_to_venue_included" value="no">
               <label class="form-check-label" for="furnitureTransportToVenueIncludedNo">{{translation('no')}}</label>
            </div>
         </div>
         <div class="col-6">
            <label for="" class="form-label">{{translation('furnishing_in_the_venue')}}</label>
            <div class="form-check">
               <input type="radio" class="form-check-input" id='furnishingInTheVenueYes' name="furnishing_in_the_venue" value="yes" checked>
               <label class="form-check-label" for="furnishingInTheVenueYes">{{translation('yes')}}</label>
            </div>
            <div class="form-check">
               <input type="radio" class="form-check-input" id='furnishingInTheVenueNo' name="furnishing_in_the_venue" value="no">
               <label class="form-check-label" for="furnishingInTheVenueNo">{{translation('no')}}</label>
            </div>
         </div>
          <div class="col-12">
              <label for="furnishingAndDecorationExplanation" class="form-label">{{translation('furnishing_and_decoration_explanation')}}</label>
              <textarea id='furnishingAndDecorationExplanation' name="furnishing_and_decoration_explanation" class="form-control" rows="1"></textarea>
          </div>
         <div class="col-6">
            <label for="" class="form-label">{{translation('decoration_transport_included')}}</label>
            <div class="form-check">
               <input type="radio" class="form-check-input" id='decorationTransportIncludedYes' name="decoration_transport_included" value="yes" checked>
               <label class="form-check-label" for="decorationTransportIncludedYes">{{translation('yes')}}</label>
            </div>
            <div class="form-check">
               <input type="radio" class="form-check-input" id='decorationTransportIncludedNo' name="decoration_transport_included" value="no">
               <label class="form-check-label" for="decorationTransportIncludedNo">{{translation('no')}}</label>
            </div>
         </div>
         <div class="col-6">
            <label for="" class="form-label">{{translation('decoration_work_included')}}</label>
            <div class="form-check">
               <input type="radio" class="form-check-input" id='decorationWorkIncludedYes' name="decoration_work_included" value="yes" checked>
               <label class="form-check-label" for="decorationWorkIncludedYes">{{translation('yes')}}</label>
            </div>
            <div class="form-check">
               <input type="radio" class="form-check-input" id='decorationWorkIncludedNo' name="decoration_work_included" value="no">
               <label class="form-check-label" for="decorationWorkIncludedNo">{{translation('no')}}</label>
            </div>
         </div>
          <div class="col-12">
              <label for="decoration_Description" class="form-label">{{translation('decoration_description')}}</label>
              <textarea id='decoration_Description' name="decoration_description" class="form-control" rows="1"></textarea>
          </div>
          <div class="col-12">
              <label for="details" class="form-label">{{translation('details')}}</label>
              <textarea id="details"  name="details" class="form-control"></textarea>
          </div>
</template>

<script>
/* third party selection modal */
import thirdPartySelection      from './ThirdPartySelection';

export default {
  name: "furnishing",
  props: [
   ],
   data() {
    return {

    };
  },
   methods: {
    translation(arg,replace_message){
      return trans(arg,replace_message);
    },
   }

};
</script>
