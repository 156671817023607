<template>
    <div class="col-4">
        <label for="eventProductionTeamNumber" class="form-label">{{translation('event_production_team_number')}}</label>
        <input type="number" id='eventProductionTeamNumber' name="event_production_team_number" class="form-control" value="">
    </div>
    <div class="col-4">
        <label for="cateringSelectionList" class="form-label">{{translation('catering_selection_list')}}</label>
        <select id='cateringSelectionList' name="catering_selection_list" class="form-select">
            <option value="">{{ translation('select') }}</option>
            <option value="alcohol_rights">{{translation('alcohol_rights')}}</option>
            <option value="own_drinks">{{translation('own_drinks')}}</option>
            <option value="need_to_acquire_rights">{{translation('need_to_acquire_rights')}}</option>
        </select>
    </div>
    <div class="col-4">
        <label for="quantityOfAlcoholPerVisitor" class="form-label">{{translation('quantity_of_alcohol_per_visitor')}}</label>
        <input type="text" id='quantityOfAlcoholPerVisitor' name="quantity_of_alcohol_per_visitor" class="form-control">
    </div>
    <div class="col-12">
        <label for="foodAllergies" class="form-label">{{translation('food_allergies')}}</label>
        <textarea id='foodAllergies' name="food_allergies" class="form-control" rows="1"></textarea>
    </div>
    <div class="col-3">
        <label for="" class="form-label">{{translation('selection_of_drinks')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='mild' name="selection_of_drinks" value="mild" checked>
            <label class="form-check-label" for="mild"> {{ translation('mild') }} </label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='strong' name="selection_of_drinks" value="strong">
            <label class="form-check-label" for="strong"> {{ translation('strong') }} </label>
        </div>
    </div>
    <div class="col-3">
        <label for="" class="form-label">{{translation('alcohol_sale_in_event')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='alcohol_sale_in_event_yes' name="alcohol_sale_in_event" value="yes" checked>
            <label class="form-check-label" for="alcohol_sale_in_event_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='alcohol_sale_in_event_no' name="alcohol_sale_in_event" value="no">
            <label class="form-check-label" for="alcohol_sale_in_event_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('drink_tickets')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='drink_tickets_yes' name="drink_tickets" value="yes" checked>
            <label class="form-check-label" for="drink_tickets_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='drink_tickets_no' name="drink_tickets" value="no">
            <label class="form-check-label" for="drink_tickets_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label class="form-label">{{translation('beverage_serving_work')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='offer_for_the_work_yes' name="offer_for_the_work" value="yes" checked>
            <label class="form-check-label" for="offer_for_the_work_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='offer_for_the_work_no' name="offer_for_the_work" value="no">
            <label class="form-check-label" for="offer_for_the_work_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('serving_set_included')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='serving_set_included_yes' name="serving_set_included" value="yes" checked>
            <label class="form-check-label" for="serving_set_included_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='serving_set_included_no' name="serving_set_included" value="no">
            <label class="form-check-label" for="serving_set_included_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('serving_and_buffet_decoration')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='serving_table_cloth_included_yes' name="serving_table_cloth_included" value="yes" checked>
            <label class="form-check-label" for="serving_table_cloth_included_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='serving_table_cloth_included_no' name="serving_table_cloth_included" value="no">
            <label class="form-check-label" for="serving_table_cloth_included_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('tableware_and_cutlery')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='tableware_and_cutlery_yes' name="tableware_and_cutlery" value="yes" checked>
             <label class="form-check-label" for="tableware_and_cutlery_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='tableware_and_cutlery_no' name="tableware_and_cutlery" value="no">
            <label class="form-check-label" for="tableware_and_cutlery_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('serviets_included')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='serviets_included_yes' name="serviets_included" value="yes" checked>
            <label class="form-check-label" for="serviets_included_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='serviets_included_no' name="serviets_included" value="no">
            <label class="form-check-label" for="serviets_included_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('dining_table_cloth_included')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='dining_table_cloth_included_yes' name="dining_table_cloth_included" value="yes" checked>
            <label class="form-check-label" for="dining_table_cloth_included_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='dining_table_cloth_included_no' name="dining_table_cloth_included" value="no">
            <label class="form-check-label" for="dining_table_cloth_included_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('serving_table_decoration_included')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='serving_table_decoration_included_yes' name="serving_table_decoration_included" value="yes" checked>
            <label class="form-check-label" for="serving_table_decoration_included_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='serving_table_decoration_included_no' name="serving_table_decoration_included" value="no">
            <label class="form-check-label" for="serving_table_decoration_included_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('waiters_waitresses_included')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='waiters_waitresses_included_yes' name="waiters_waitresses_included" value="yes" checked>
            <label class="form-check-label" for="waiters_waitresses_included_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='waiters_waitresses_included_no' name="waiters_waitresses_included" value="no">
            <label class="form-check-label" for="waiters_waitresses_included_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('kitchen_personnel_included')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='kitchen_personnel_included_yes' name="kitchen_personnel_included" value="yes" checked>
            <label class="form-check-label" for="kitchen_personnel_included_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='kitchen_personnel_included_no' name="kitchen_personnel_included" value="no">
            <label class="form-check-label" for="kitchen_personnel_included_no">{{translation('no')}}</label>
        </div>
    </div>

    <div class="col-3">
        <label for="" class="form-label">{{translation('table_and_buffet_table_setting')}}</label>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='table_and_buffet_table_setting_yes' name="table_and_buffet_table_setting" value="yes" checked>
            <label class="form-check-label" for="table_and_buffet_table_setting_yes">{{translation('yes')}}</label>
        </div>
        <div class="form-check">
            <input type="radio" class="form-check-input" id='table_and_buffet_table_setting_no' name="table_and_buffet_table_setting" value="no">
            <label class="form-check-label" for="table_and_buffet_table_setting_no">{{translation('no')}}</label>
        </div>
    </div>
    <div class="col-12">
        <label for="details" class="form-label">{{translation('details')}}</label>
        <textarea id="details"  name="details" class="form-control"></textarea>
    </div>
    <div class="col-6">
        <table class="table-primary w-100">
            <thead>
            <tr>
                <th scope="col" style="width: 25px;"></th>
                <th scope="col">{{translation('catering_type')}}</th>
                <th scope="col">{{translation('additional_information')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(catering, k) in caterings" :key="k">
                <td scope="row">
                    <div class="form-check">
                        <input type="checkbox" name="caterings[]" class="form-check-input list-checkbox" :value="catering.id" checked>
                    </div>
                </td>
                <td v-html="catering['cateringTypeName']"></td>
                <td v-html="catering['additionalInformation']"></td>
            </tr>
            </tbody>
        </table>
        <input class="form-check-input" type="hidden" name="caterings" disabled />
    </div>

</template>

<script>

export default {
  name: "catering",
   props: [
        'caterings',
   ],
   data() {
    return {
    };
  },
   methods: {
    translation(arg,replace_message){
      return trans(arg,replace_message);
    },
   }

};
</script>
