<template>
    <table class="table-primary w-100 mt-3">
        <thead>
        <tr>
            <th v-if="offerStatus === 1">{{translation('select')}}</th>
            <th>{{translation('offer_type')}}</th>
            <th>{{translation('third_party')}}</th>
            <th>{{translation('offer_status')}}</th>
            <th v-if="offerStatus === 3 || offerStatus === 4"></th>
            <th></th>
            <th v-if="offerStatus === 2"></th>
            <th v-if="offerStatus === 3"></th>
            <th v-if="offerStatus === 2"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="offer in offersList">
            <td v-if="offerStatus === 1">
                <div class="form-check">
                    <input type="checkbox" name="offer_list_id[]" class="form-check-input" :value="offer.id" checked>
                </div>
            </td>
            <td>{{ translation(offer.offerType) }}</td>
            <td>{{offer.thirdParty}}</td>
            <td>{{offer.offerStatus}}</td>
            <td v-if="offer.offerStatus === translation('offer_accepted')">
                <a href="javascript:;" @click="select(offer.id)">{{translation('select')}}</a>
            </td>
            <td v-if="offer.offerStatus === translation('offer_declined')">
                <a href="javascript:;" @click="hide(offer.id)">{{translation('hide')}}</a>
            </td>
            <td><a href="" @click.prevent="showModal(offer.uuid)" >{{translation('view')}}</a></td>
            <td v-if="offerStatus === 2"><a href="javascript:;" @click="acceptOffer(offer.id)">{{translation('accept')}}</a></td>
            <td v-if="offerStatus === 3"><a href="javascript:;" @click="declineOffer(offer.id)">{{ translation('decline') }}</a></td>
            <td v-if="offerStatus === 2"><a href="javascript:;" @click="cancelOffer(offer.id)">{{translation('cancel')}}</a></td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {Modal} from "bootstrap";

export default {
    name: "OffersTable",
    props: [
        'offerStatus',
        'refreshList'
    ],
    data() {
        return {
            offersList: [],
        };
    },
    watch: {
        refreshList: function(newVal, oldVal) {
            this.loadData()
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },

        loadData(){
            var url = 'offersList/' + this.offerStatus;
            var axiosRequest = getWithAxios(url);

            axiosRequest.then((data) => {
                this.offersList = data.data;
            });
        },

        showModal(offerId){
            var url = 'offer-details/'+offerId;

            var axiosRequest = getWithAxios(url);

            axiosRequest.then((data) => {
                if(data.success == true){
                    document.getElementById('offerDetailsModelBody').innerHTML = data.html;
                    document.getElementById('offerRequestAttachment').innerHTML = data.attachmentHtml;
                    document.getElementById('thirdPartyOfferDetailsLabel').innerHTML = this.translation('details');
                    var fields = document.getElementById("offerDetailsModelBody").getElementsByClassName('form-control');

                    for(var i = 0; i < fields.length; i++)
                    {
                        fields[i].disabled = true;
                    }

                    var offersDetailsModal = new Modal(document.getElementById('thirdPartyOfferDetails'));
                    offersDetailsModal.show();
                }
            });
        },

        select(offerId){
            var actionType = 'select';
            var url = 'thirdparty-offer-action/'+offerId+'/'+actionType;

            var axiosRequest = getWithAxios(url);
            axiosRequest.then((data) => {
                if (data.success == true) {
                    //toast.success(data.message);
                    toastMessage(data.message, 'success');
                    this.loadData();
                }
            });
        },

        hide(offerId){
            var actionType = 'hide';
            var url = 'thirdparty-offer-action/'+offerId+'/'+actionType;

            var axiosRequest = getWithAxios(url);
            axiosRequest.then((data) => {
                if (data.success == true) {
                    //toast.success(data.message);
                    toastMessage(data.message, 'success');
                    this.loadData();
                }
            });
        },
        acceptOffer(offerId) {
            var url = 'accept-offer/'+offerId;

            var axiosRequest = getWithAxios(url);
            axiosRequest.then((data) => {
                if (data.success == true) {
                    //toast.success(data.message);
                    toastMessage(data.message, 'success');
                    this.$emit('switchTab', 'received_offers');
                }
            });
        },
        declineOffer(offerId) {
            if (confirm(this.translation('decline_offer_request_confirm'))) {
                var url = 'decline-offer/'+ offerId;

                var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                    if (data.success == true) {
                        toastMessage(data.message, 'success');
                        this.$emit('switchTab', 'declined_offers');
                    }
                });
            }
        },
        cancelOffer(offerId) {
            var url = 'cancel-offer/'+offerId;

            var axiosRequest = getWithAxios(url);
            axiosRequest.then((data) => {
                if (data.success == true) {
                    //toast.success(data.message);
                    toastMessage(data.message, 'success');
                    this.$emit('switchTab', 'send_offers');
                }
            });
        },
    }
}
</script>

<style scoped>

</style>
