<template>
    <div class="col-lg-6 col-md-12 mb-3 pr-2">
        <div class="dashboard-box bg-white blue-rounded-border p-3" id="projectsPlanning">
            <h2 class="text-primary">{{ translation('ongoing_projects') }}</h2>
            <table class="table-primary w-100" id="planningProjects">
                <thead>
                    <tr>
                        <th>{{ translation('event_name') }}</th>
                        <th>{{ translation('version') }}</th>
                        <th>{{ translation('client') }}</th>
                        <th>{{ translation('start') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list,key in projectList">
                        <td><a :href="list.link">{{list.eventName}}</a></td>
                        <td>{{list.version}}</td>
                        <td>{{list.customer}}</td>
                        <td>{{list.start}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-holder text-center pagination-placement">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center">
                        <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "OngoingProjects",
    mounted() {
        this.loadData();
    },
    data() {
        return {
            projectList : [],
            pagination : [],
        };
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url){
            var projectStatus = 1 // 1 = ongoing projects
            var url = url ? url : 'get-projects/'+projectStatus;
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.projectList = data.data.projectData;
                this.pagination = data.data.pagination;
            });
        },
        paginationLink(url){
            this.loadData(url);
        },

    }
}
</script>


