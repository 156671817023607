<template>
    <div class="event_create">

    </div>
    <h2 class="text-primary">{{ translation('users') }}</h2>
    <div class="offset-md-8 col-4">
        <div class="input-group mb-3">
            <input type="text" id="user_search" class="form-control" @keyup="search" :placeholder="translation('user_search')" value="" aria-describedby="button-addon2">
        </div>
    </div>
    <table class="table-primary w-100" id="planningProjects">
        <thead>
            <tr>
                <th>{{ translation('name') }}</th>
                <th>{{ translation('email') }}</th>
                <th>{{ translation('role') }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="list,key in thirdPartyList">
                <td>{{list.name}}</td>
                <td>{{list.email}}</td>
                <td>{{ translation(list.role) }}</td>
                <td><a href="" @click.prevent="showModal(list.id)" >{{translation('view')}}</a></td>
            </tr>
        </tbody>
    </table>
    <div class="pagination-holder text-center">
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
            </ul>
        </nav>
    </div>


    <div class="modal fade" id="userDetails" tabindex="-1" aria-labelledby="userDetailsLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userDetailsLabel">{{translation('user_details')}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" id="userDetailsModalBody"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ translation('close') }}</button>
            </div>
        </div>
    </div>
    </div>



</template>
<script>
import { Modal } from 'bootstrap';
export default {
    name: "ThirdPartyList",
    mounted() {
        this.loadData();
    },
    data() {
        return {
            thirdPartyList : [],
            pagination : [],
        };
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url,queryParam){
            var searchString = queryParam ? queryParam : '';
            var url = url ? url : '/get-all-users?queryParams='+searchString;
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.thirdPartyList = data.usersDataArray;
                this.pagination = data.pagination;
            });
        },
        paginationLink(url){
            this.loadData(url);
        },
        search(){
            var searchString = document.getElementById('user_search').value;
            var url = null;
            this.loadData(url,searchString);
        }   ,
        showModal(userId){

            var url = 'user-details/'+userId;
            var axiosRequest = getWithAxios(url);
            axiosRequest.then((data) => {
                document.getElementById('userDetailsModalBody').innerHTML = data.html;
                var myModal = new Modal(document.getElementById('userDetails'));
                myModal.show();
            });
        },

    }
}
</script>


