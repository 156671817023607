<template>
    <h2 class="text-primary">{{ licesenceData ? translation('update_license')  : translation('create_license') }}</h2>
    <form autocomplete="off" id="lisenceCreate">
        <input type="hidden" id='' name="license_id" class="form-control" :value="licesenceData ? licesenceData.id : ''">

        <div class="row g-3">
            <div class="col-6" v-if="licesenceData">
                <label for="licenseCode" class="form-label">{{translation('license_code')}}</label>
                <input type="text" readonly id='licenseCode' name="license_code" class="form-control" :value="licesenceData.license_code">
            </div>
            <div class="col-6">
                <label for="licenseType" class="form-label">{{translation('license_type')}}</label>
                <select id="licenseType" name="license_type" class="form-select"  v-model="selectedType" @change="changeLicenseType">
                    <option value="">{{translation('select_license_type')}}</option>
                    <option value="1" >{{translation('mini')}}</option>
                    <option value="2" >{{translation('basic')}}</option>
                    <option value="3" >{{translation('super')}}</option>

                </select>
            </div>
            <div class="col-4" v-if="licesenceData">
                <label for="licenseExpire" class="form-label">{{translation('expire')}}</label>
                <input type="text" name="expire_date" id="licenseExpire" class="form-control" value="">
            </div>
            <div class="col-4" v-if="licesenceData">
                <label for="maxProject" class="form-label">{{translation('maximum_project')}}</label>
                <input type="text" id="maxProject" name="maximum_project" class="form-control" value="">
            </div>
            <div class="col-4" v-if="licesenceData">
                <label for="organizationName" class="form-label">{{translation('organization')}}*</label>
                <input type="text" id="organizationName" name="organization_name" class="form-control" value="">
            </div>

            <div class="col">
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"> {{translation('select_user')}} </button>
            </div>
            <div class="col" v-if="licesenceData">
                <button type="button" @click="renewLicense" class="btn btn-primary" > {{translation('add_one_year_to_license')}} </button>
                <input type="hidden" id="renewLicense" name="renew_license" class="form-control" value="">
            </div>

        </div>
        <br>
        <div class="row g-3">
            <div class="col-4">
                <div class="form-check checkbox-party" id="checkbox-party" v-for="user,key in selectedUser">
                    <input class="form-check-input" :id="key" type="checkbox" checked name="user[]" :value="user.id">
                    <label class="form-check-label" :for="key"> {{user.name}}</label>
                </div>
                <input class="form-check-input" type="hidden" name="user" disabled>
            </div>
        </div>
    </form>

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{translation('search_user')}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <input type="text" id='userSearch' name="" @keyup="getUserList()" class="form-control" :placeholder="translation('search_user')" >
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <table class="table-primary w-100" id="planningProjects">
                            <thead>
                                <tr>
                                    <th>{{ translation('name') }}</th>
                                    <th>{{ translation('email') }}</th>
                                    <th>{{ translation('role') }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="list,key in userList">
                                    <td>{{list.name}}</td>
                                    <td>{{list.email}}</td>
                                    <td>{{list.role}}</td>
                                    <td><button class="btn btn-default" @click.prevent="selectUser(list)" ><i class="fas fa-plus"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="pagination-holder text-center">
                            <nav aria-label="Page navigation">
                                <ul class="pagination justify-content-center">
                                    <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ translation('close') }}</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "licenseCreate",
    props: [
        'licesenceData',
    ],
    data() {
        return {
             pagination : [],
             userList : [],
             selectedUser : [],
             selectedType : '',
        };
    },
    mounted() {
        if(this.licesenceData){
            this.selectedType = this.licesenceData.license_type;
            this.selectedUser = this.licesenceData.userIdArray;
            document.getElementById('licenseExpire').value = this.licesenceData.expire;
            document.getElementById('maxProject').value = this.licesenceData.maximum_project;
            document.getElementById('organizationName').value = this.licesenceData.organizationName;

        }
        document.getElementById("adminFooterButton").setAttribute("data-url",'/create-update-license');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'lisenceCreate');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },

    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        getUserList(url){
            var searchString = document.getElementById('userSearch').value;
            var url = url ? url : '/get-only-users?queryParams='+searchString;
            var axiosRequest = getWithAxios(url);
            axiosRequest.then((data) => {
                // document.getElementById('htmlHolder').innerHTML = data.usersHtml;
                this.pagination     = data.pagination;
                this.userList       = data.usersData.data;
            });
        },
        paginationLink(url){
            var searchString = document.getElementById('userSearch').value;
            var params =  '&queryParams='+searchString;
            console.log(url+params);
            this.getUserList(url+params);
        },
        selectUser(list)
        {
            var isExists = selectedUserExists(this.selectedUser,list.id);
            if(!isExists){
                this.selectedUser.push(list);
            }else{
                //toastr.error(this.translation('user_already_added'));
                toastMessage(trans("user_already_added"), "error");
            }
        },
        closeModal(){
            this.thirdPartySearch = false;
        },
        renewLicense(){
            if (confirm(this.translation('confirm_renew_license_one_year'))) {
                document.getElementById('renewLicense').value = 1;
                var expiredDate = document.getElementById('licenseExpire').value;
                var expiredDateArr = expiredDate.split('.');
                var expiredDateYear = expiredDateArr.length == 3 ? parseInt(expiredDateArr[2]) : null;

                if (expiredDateYear) {
                    var expiredDateYearPlusOne = expiredDateYear + 1;
                    var newExpiredDate = parseInt(expiredDateArr[0]) + '.' + parseInt(expiredDateArr[1]) + '.' + expiredDateYearPlusOne;
                    document.getElementById('licenseExpire').value = newExpiredDate;
                }
            } else {
                document.getElementById('renewLicense').value = 0;
                return false;
            }
        },
        changeLicenseType(event) {
            if (document.getElementById('maxProject')) {
                if (event.target.value == 1) {
                    document.getElementById('maxProject').value = 1;
                } else if(event.target.value == 2) {
                    document.getElementById('maxProject').value = 10;
                } else if(event.target.value == 3) {
                    document.getElementById('maxProject').value = 100;
                }
            }
        }
    }
}
</script>


