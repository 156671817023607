<template>
    <div class="col-12">
        <label for="details" class="form-label">{{translation('details')}}</label>
        <textarea id="details" name="details" class="form-control"></textarea>
    </div>
    <div class="col-6">
        <table class="table-primary w-100">
            <thead>
            <tr>
                <th scope="col" style="width: 25px;"></th>
                <th>{{translation('technology_name')}}</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="(technology, k) in technologies" :key="k">
                    <td scope="row">
                        <div class="form-check">
                            <input type="checkbox" name="technologies[]" class="form-check-input list-checkbox" :value="technology.id" checked>
                        </div>
                    </td>
                    <td v-html="technology['technology']"></td>
                </tr>
            </tbody>

        </table>
        <input class="form-check-input" type="hidden" name="technologies" disabled />
    </div>
</template>

<script >

export default {
    name: "technology",
    props: [
        'technologies'
    ],
    data() {
        return {
        };
    },
    methods: {
        translation(arg, replace_message) {
            return trans(arg, replace_message);
        },
    }

};

</script>
