<template>
        <div class="col-lg-6 col-md-12 mb-3 pl-2">
            <div class="dashboard-box bg-white blue-rounded-border p-3" id="declinedOrders">
                <h2 class="text-primary">{{ translation('declined_offers') }}</h2>
                <table class="table-primary w-100" id="">
                    <thead>
                        <tr>
                            <th>{{ translation('name_of_event') }}</th>
                            <th>{{ translation('organization') }}</th>
                            <th>{{ translation('due') }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="list,key in offerList">
                            <td>{{list.eventName}}</td>
                            <td>{{list.customer}}</td>
                            <td>{{list.dueDate}}</td>
                            <td><a href="" @click.prevent="showModal(list.uuId)" >{{ translation('view') }}</a></td>
                        </tr>
                    </tbody>
                </table>
                <div class="pagination-holder text-center pagination-placement">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="modal fade" id="thirdPartyDeclinedOfferDetails" tabindex="-1" aria-labelledby="thirdPartyOfferDetailsLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="thirdPartyDeclinedOfferDetailsLabel"></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div id="declinedOfferDetailsModelBody">
                        </div>
                        <div v-if="attachmentFileId && attachmentName" class="mt-3">
                            <h5>{{ translation('attachment') }}</h5>
                            <a :href="href">{{ attachmentName }}</a>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ translation('close') }}</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { Modal } from 'bootstrap';
export default {
  name: "DeclinedOrder",
    mounted() {
        this.loadData();
    },
    data() {
        return {
            offerList : [],
            pagination : [],
            attachmentFileId: null,
            attachmentName: '',
            href: '',
        };
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url){
            var offerStatus = 4 // 4 = kdeclined offers
            var url = url ? url : 'get-third-party-offer-request/'+offerStatus;
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.offerList = data.data.offersContent;
                this.pagination = data.data.pagination;
            });
        },
        paginationLink(url){
            this.loadData(url);
        },
        showModal(offerId){
            var url = 'offer-details/'+offerId;
            const self = this;
            var axiosRequest = getWithAxios(url);
            axiosRequest.then((data) => {
                if(data.success == true){
                    self.attachmentFileId = data.fileId;
                    self.attachmentName = data.fileName;
                    self.href = '/download-attachment/' + data.fileId;
                    document.getElementById('declinedOfferDetailsModelBody').innerHTML = data.html;
                    document.getElementById('thirdPartyDeclinedOfferDetailsLabel').innerHTML = data.eventName;
                    var myModal = new Modal(document.getElementById('thirdPartyDeclinedOfferDetails'));
                    myModal.show();
                }
            });
        },
    }
}
</script>
