<template>
    <h2 class="text-primary">{{ translation('add_picture') }}</h2>
    <form autocomplete="off" id="addPicture">
        <input type="hidden"  name="picture_type_id" class="form-control" :value="editData.pictureTypeId">
        <div class="row g-4">
            <div class="col-6">
                <label for="eventTypeId" class="form-label">{{translation('event_type_name')}}</label>
                <select name="event_type_id" id="eventTypeId" class="form-select" @change="changeEventType">
                    <option value="">{{translation('select')}}</option>
                    <option v-for="data in eventTypeList" :value="data.id">
                        {{ data.eventType }}
                    </option>
                </select>
            </div>
        </div>
        <br>
        <div class="row g-3" v-if="this.isPictureLoaded">
            <div class="col-12">
                <label for="picture1" class="form-label d-block">{{translation('select_picture')}} ({{ translation('max_200_pictures') }})</label>
                <file-upload @changeMaxNumberOfFiles="updateMaxNumberOfFiles" name="pictureType" :buttonNumber="1" :buttonText="this.buttonText" buttonId="picture1" buttonClasses="btn btn-secondary" :maxFileSize="this.maxFileSize" :allowedFileTypes="this.allowedFileTypes" :maxNumberOfFiles="this.maxNumberOfFiles" :eventTypeId="this.eventTypeId" :pictureTypeId="editData.pictureTypeId" :buttonDisabled="this.isDisabled" uploadUrl="add-picture" deleteUrl="delete-picture" :pictureSrcs="this.pictureSrcs" />
            </div>
        </div>
    </form>
</template>
<script>
import FileUpload from '../FileUpload.vue';

export default {
    name: "addPicture",
    props: [
        'editData'
    ],
    components:{
        FileUpload
    },
    mounted() {
        document.getElementById("adminFooterButton").setAttribute("data-url",'');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
        this.loadData();
    },
    data() {
        return {
            eventTypeList : [],
            buttonText: trans('upload_picture'),
            maxFileSize: 100000000,
            allowedFileTypes: ['image/*'],
            maxNumberOfFiles:200,
            eventTypeId: '',
            isDisabled: true,
            isPictureLoaded: false,
            pictureSrcs: {}
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(){
            var url = 'get-event-types';
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.eventTypeList = data.eventTypeData;
            });          
        },
        changeEventType(event) {
            this.eventTypeId = event.target.value;

            if (this.eventTypeId) {
                let axiosRequest = getWithAxios('/eventtype/'+ this.eventTypeId +'/picturetype/'+ this.editData.pictureTypeId +'/pictures');
                axiosRequest.then((data) => {
                    
                    if (data.numberOfPictures >= 200) {
                        this.maxNumberOfFiles = 0;
                        this.isDisabled = true;
                    } else {
                        this.maxNumberOfFiles = 200 - data.numberOfPictures;
                        this.isDisabled = false;
                    }
                    
                    if (data.pictureSrcs) {
                        this.pictureSrcs = data.pictureSrcs;
                    }
                    
                    this.isPictureLoaded = true;
                });
            } else {
                this.isDisabled = true;
            }
        },
        updateMaxNumberOfFiles(value) {
            this.maxNumberOfFiles = value;
        }
    }
}
</script>


