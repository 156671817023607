<template>
            <table class="table-primary w-100" id="planningProjects">
                <thead>
                    <tr>
                        <th>{{ translation('event_name') }}</th>
                        <th>{{ translation('name_of_event_organization') }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list,key in projectList">
                        <td><a :href="list.link">{{list.eventName}}</a></td>
                        <td>{{list.customer}}</td>
                        <td><a :href="list.useTemplate">{{translation('use_this')}}</a></td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-holder text-center">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center">
                        <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
                    </ul>
                </nav> 
            </div>
</template>
<script>

export default {
    name: "TemplateList",
    mounted() {
        this.loadData();
    },
    data() {
        return {
            projectList : [],
            pagination : [],
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url){
            var projectStatus = 1
            var isTemplate = 1
            var url = url ? url : 'get-projects/'+projectStatus+'/'+isTemplate;
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.projectList = data.data.projectData;
                this.pagination = data.data.pagination;
            });            
        },
        paginationLink(url){
            this.loadData(url);
        },

    }
}
</script>


