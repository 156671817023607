<template>
    <admin-list             v-if="activePage === 'list'"                @addPrice="addPrice"     />
    <admin-add-price        v-if="activePage === 'price'"           :editData="this.editData"     />
</template>
<script>
 import adminList     from  './AdminList';
 import adminAddPrice     from  './AdminAddPrice';
export default {

    name: "AdminBox",
    components: {
      adminList,
      adminAddPrice,
    },
    data() {
        return {
            activePage :'',
            editData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        addPrice(adminData){
            this.activePage = 'price';
            this.editData = adminData;
        }
    },
}
</script>


