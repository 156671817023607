<template>
    <div class="col-12">
        <label for="details" class="form-label">{{translation('details')}}</label>
        <textarea id="details"  name="details" class="form-control"></textarea>
    </div>
    <div class="col-6">
        <table class="table-primary w-100">
            <thead>
            <tr>
                <th scope="col" style="width: 25px;"></th>
                <td scope="col">{{translation('performer_type')}}</td>
                <td scope="col">{{translation('duration')}}</td>
                <td scope="col">{{translation('additional_information')}}</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(performer, k) in performers" :key="k">
                <td scope="row">
                    <div class="form-check">
                        <input type="checkbox" name="performers[]" class="form-check-input list-checkbox" :value="performer.id" checked>
                    </div>
                </td>
                <td v-html="performer['performerTypeName']"></td>
                <td v-html="performer['performerDuration']"></td>
                <td v-html="performer['additionalInformation']"></td>
            </tr>
            </tbody>
        </table>
        <input class="form-check-input" type="hidden" name="performers" disabled />
    </div>
</template>

<script>
/* third party selection modal */

export default
{
    name: "createOffer",
    props: [
        //'offerId',
        //'time',
        //'thirdPartyFilterdData',
        //'participant',
        'performers',
    ],
    components:
    {
        //thirdPartySelection,
    },
    data()
    {
        return {
        };
    },
    methods:
    {
        translation(arg, replace_message)
        {
            return trans(arg, replace_message);
        }
    }
}
</script>
