<template>
    <ReceivedOffer @loadSection="loadSection" />
    <SelectedOrder />
    <AcceptedOrder :key="index" />
    <DeclinedOrder :key="index" />
</template>

<script>

import ReceivedOffer from './ReceivedOffer';
import AcceptedOrder from './AcceptedOrder';
import DeclinedOrder from './DeclinedOrder';
import SelectedOrder from './SelectedOrder';

export default {
    name: "ProjectBox",
    components:{
        ReceivedOffer,
        AcceptedOrder,
        DeclinedOrder,
        SelectedOrder,
    },
    data() {
        return {
            index: 1
        }
    },    
   methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadSection(){
            this.index++
        }
    }
}
</script>

        
        
        
        
