<template>
    <ongoing-projects />
    <project-templates />
    <received-offer />
    <finished-projects :user-type="userType"/>
</template>

<script>
import OngoingProjects  from    './OngoingProjects';
import ProjectTemplates from    './ProjectTemplates';
import ReceivedOffer    from    './ReceivedOffer';
import FinishedProjects from    './FinishedProjects';
export default {
    name: "Dashboard",
    props:[
        'userType'
    ],
    components:{
        OngoingProjects,
        ProjectTemplates,
        ReceivedOffer,
        FinishedProjects
    },
    mounted(){
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
    }
}
</script>
