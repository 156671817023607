<template>
    <div class="event_create">
        <a href="" @click.prevent="create()" class="btn btn-primary btn-sm col-2">{{translation('create_license')}}</a>
    </div>
    <h2 class="text-primary">{{ translation('license') }}</h2>
    <table class="table-primary w-100" id="planningProjects">
        <thead>
            <tr>
                <th>{{ translation('license_code') }}</th>
                <th>{{ translation('license_type') }}</th>
                <th>{{ translation('expires') }}</th>
                <th>{{ translation('users') }}</th>
                <th>{{ translation('organization') }}</th>
                <th>{{ translation('edit') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="list,key in lisenceList">
                <td>{{list.license_code}}</td>
                <td>{{list.license_type}}</td>
                <td>{{list.expire}}</td>
                <td>{{list.users}}</td>
                <td>{{list.organizationName}}</td>
                <td><a href="" @click.prevent="licenseEdit(list)" >{{translation('edit')}}</a></td>
            </tr>
        </tbody>
    </table>
    <div class="pagination-holder text-center">
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
            </ul>
        </nav>
    </div>


    <div class="modal fade" id="userDetails" tabindex="-1" aria-labelledby="userDetailsLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userDetailsLabel">{{translation('user_details')}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" id="userDetailsModalBody"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
    </div>



</template>
<script>
import { Modal } from 'bootstrap';
export default {
    name: "ThirdPartyList",
    mounted() {
        this.loadData();
    },
    data() {
        return {
            lisenceList : [],
            pagination : [],
        };
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url){
            var url = url ? url : '/get-all-licenses';
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.lisenceList = data.allLicenses;
                this.pagination = data.pagination;
            });
        },
        paginationLink(url){
            this.loadData(url);
        },
        create(){
            this.$emit('createLicense');
        },
        licenseEdit(list){
            this.$emit('licenseEdit',{...list});
        }


    }
}
</script>


