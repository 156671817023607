<template>
    <catering-type-list         v-if="activePage === 'list'"    @createCateringType="createCateringType" @editCateringType="editCateringType"    />
    <catering-type-create       v-if="activePage === 'create'"      />
    <catering-type-edit         v-if="activePage === 'edit'"       :editData="this.editData" />
</template>

<script>
 import cateringTypeList        from  './CateringTypeList';
 import cateringTypeCreate      from  './CateringTypeCreate';
 import CateringTypeEdit        from  './CateringTypeEdit';
export default {
   
    name: "CateringTypeBox",
    components: {
      cateringTypeList,
      cateringTypeCreate,
      CateringTypeEdit,
    },
    data() {
        return {
            activePage :'',
            editData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        createCateringType(){
            this.activePage = 'create';
        },
        editCateringType(cateringType){
            this.activePage = 'edit';
            this.editData = cateringType;
        }
    },
}
</script>


