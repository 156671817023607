<template>
    <form autocomplete="off" id="assignOfferRequestType">
    <div class="row g-3">
    <h3 class="text-primary">{{ translation('assign_offer_type') }}</h3>
        <div class="">
            <label for="timeFrameName" class="form-label">{{translation('third_party')}} : {{editData.name}}</label>
            <input type="hidden" name="third_party_id" :value="editData.id">
        </div>
        <div class="col-6" v-for="value in offerRequestType">
            <div class="form-check">
                <input type="checkbox" :id="this.createId(value.name)" class="form-check-input" v-model="selectedTechnologies" name="offer_request_type[]" :value="translation(value.id)">
                <label class="form-check-label" :for="this.createId(value.name)">{{ translation(value.name)}}</label>
            </div>
        </div>
        <input class="form-check-input" type="hidden" name="offer_request_type" disabled>

    </div>
</form>
</template>
<script>

export default {
    name: "EventTimeFrameCreate",
    props: [
        'editData'
    ],    
    mounted() {
        this.loadData();
        document.getElementById("adminFooterButton").setAttribute("data-url",'assign-offer-request-type');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'assignOfferRequestType');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },
    data() {
        return {
            offerRequestType:'',
            selectedTechnologies:[],
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(){
            var url = 'get-offer-request-types/'+this.editData.id;
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.offerRequestType = data.requestTypeArray;
                this.selectedTechnologies = data.thirdPartyOffer;
                console.log(this.selectedTechnologies);
            });          
        },
        createId(string){
            var lowerCase = string.toLowerCase();
            var withOutSpecialCarecter = lowerCase.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
            var camelCase = withOutSpecialCarecter.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
            return camelCase+'-';
        },
        
    }
}
</script>


