<template>
    <event-sub-type-list @createEventSubType="eventSubTypeCreate" @editSubEventType="eventSubTypeEdit"  v-if="activePage === 'list'" />
    <event-sub-type-create v-if="activePage === 'create'" />
    <event-sub-type-edit   v-if="activePage === 'edit'" :subEventId="this.editData"/>
</template>
<script>
 import eventSubTypeList        from  './EventSubTypeList';
 import eventSubTypeCreate      from  './EventSubTypeCreate';
 import eventSubTypeEdit        from  './EventSubTypeEdit';
export default {
   
    name: "EventTypeBox",
    components: {
      eventSubTypeList,
      eventSubTypeCreate,
      eventSubTypeEdit,
    },
    data() {
        return {
            activePage :'',
            editData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        eventSubTypeEdit(eventSubTypeData){
            this.activePage = 'edit';
            this.editData = eventSubTypeData;
        },
        eventSubTypeCreate(){
            this.activePage = 'create';
        }
    },
}
</script>


