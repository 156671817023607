<template>
  <div class="col-6">
    <label class="form-label">{{ translation('event_type') }}*</label>
    <select name="event_type_id" v-model="event_type_selected" @change="getSubEventOptions($event)" class="form-select" id="eventTypeId">
      <option value="">{{translation('select')}}</option>
      <option v-for="eventType in eventTypes" :value="eventType.id">
        {{ eventType.name }}
      </option>
    </select>
  </div>
  <div class="col-6">
    <label class="form-label">{{ translation('event_sub_type') }}*</label>
    <select name="event_sub_type_id" v-model="sub_event_type_selected" class="form-select" id="eventSubTypeId" @change="updateGrandTotal($event);">
      <option value="">{{translation('select')}}</option>
      <option v-for="subEvent in filteredSubEventType" :value="subEvent.id" :event_type_id="subEvent.event_type_id">
        {{ subEvent.name }}
      </option>
    </select>
  </div>
</template>

<script>

import Basic from './Basic';
export default {
  name: "EventType",
  props: {
      eventData: {
        type: Object,
        required: true
      },
    },
  data() {
    return {
      eventTypes: [],
      allSubEventType: [],
      filteredSubEventType: [],
      selectedEventType: "",
      event_type_selected: "",
      sub_event_type_selected: "",

    };
  },
  mounted() {
    this.getEventTypes();
    this.setDataForUpdateMode();
  },
  methods: {
     translation(arg,replace_message){
      return trans(arg,replace_message);
    },

    getEventTypes() {
          this.eventTypes               =   this.eventData.event;
          this.allSubEventType          =   this.eventData.sub_event;
    },
    getSubEventOptions(eventId) {
      var event_id = eventId.target.value;
      var result = this.allSubEventType.filter((obj) => {
        return obj.event_type_id == event_id;
      });
      this.filteredSubEventType = result;
    },
    setDataForUpdateMode() {
      var event_type_id = document.getElementById("hidden_event_type_id").value;
      var sub_event_type_id = document.getElementById("hidden_event_sub_type_id").value;
      if (event_type_id) {
        this.event_type_selected = event_type_id;
        var result = this.allSubEventType.filter((obj) => {
          return obj.event_type_id == event_type_id;
        });
        this.filteredSubEventType = result;
        this.sub_event_type_selected = sub_event_type_id;
        console.log(this.sub_event_type_selected);
      }
    },
      updateGrandTotal() {
          Basic.methods.sumGrandTotal();
      }
  },
};
</script>
