<template>
    <h2 class="text-primary">{{ translation('create_picture_type') }}</h2>
    <form autocomplete="off" id="pictureTypeCreate">
    <div class="row g-3">
        <div class="col-6">
            <label for="pictureTypeName" class="form-label">{{translation('picture_type_name')}}</label>
            <input type="text" id='pictureTypeName' name="picture_type_name" class="form-control" value="">
        </div>
    </div>
</form>
</template>
<script>

export default {
    name: "PictureTypeCreate",
    mounted() {
        document.getElementById("adminFooterButton").setAttribute("data-url",'create-picture-type');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'pictureTypeCreate');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },
    data() {
        return {
            
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        

    }
}
</script>


