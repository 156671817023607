<template>
    <div class="top-menu-container mt-3">
        <a href="javascript:;" v-bind:class="{'offer-top-menu-items':true, 'offer-active':(activeTab === 'create_offers')}" id="create-offer" @click="activeTab = 'create_offers', manageFooterButton()">{{translation('create')}}</a>
        <a href="javascript:;" v-bind:class="{'offer-top-menu-items':true, 'offer-active':(activeTab === 'send_offers')}" class="offer-top-menu-items" id="send-offer" @click="activeTab = 'send_offers', manageFooterButton()">{{translation('send')}}</a>
        <a href="javascript:;" v-bind:class="{'offer-top-menu-items':true, 'offer-active':(activeTab === 'sent_offers')}" class="offer-top-menu-items" id="sent-offer" @click="activeTab = 'sent_offers', manageFooterButton()">{{translation('sent')}}</a>
        <a href="javascript:;" v-bind:class="{'offer-top-menu-items':true, 'offer-active':(activeTab === 'received_offers')}" id="received-offer" @click="activeTab = 'received_offers', manageFooterButton()">{{translation('received')}}</a>
        <a href="javascript:;" v-bind:class="{'offer-top-menu-items':true, 'offer-active':(activeTab === 'declined_offers')}" id="declined-offer" @click="activeTab = 'declined_offers', manageFooterButton()">{{ translation('declined') }}</a>
        <a href="javascript:;" v-bind:class="{'offer-top-menu-items':true, 'offer-active':(activeTab === 'selected_offers')}" id="selected-offer" @click="activeTab = 'selected_offers', manageFooterButton()">{{translation('selected')}}</a>
    </div>
    <div class="create-offer-content" v-if="activeTab === 'create_offers'">
        <form autocomplete="off" id="offerRequestForm">
            <div class="row g-3">
                <div class="col-12 row g-3">
                    <div class="col-12">
                        <label for="offerRequestType" class="form-label">{{ translation('offer_request_type')}}</label>
                        <select id="offerRequestType" class="form-select" v-model="requestType" name="offer_id" @change="changeRequestType">
                            <option v-for="option in offerRequestTypeOptions" v-bind:value="option.value">{{ translation(option.text)}}</option>
                        </select>
                    </div>
                    <div class="col-4">
                        <label for="eventStart" class="form-label">{{translation('event_start')}}</label>
                        <input type="text" id="eventStart" name="event_start" class="form-control" :value="time.eventStart" readonly />
                    </div>
                    <div class="col-2">
                        <label for="eventStartTime" class="form-label">{{translation('time')}}</label>
                        <input type="time" id="eventStartTime" name="event_start_time" class="form-control" :value="time.eventStartTime" readonly />
                    </div>
                    <div class="col-4">
                        <label for="eventEnd" class="form-label">{{translation('event_end')}}</label>
                        <input type="text" id="eventEnd" name="event_end" class="form-control" :value="time.eventEnd" readonly />
                    </div>
                    <div class="col-2">
                        <label for="eventEndTime" class="form-label">{{translation('time')}}</label>
                        <input type="time" id="eventEndTime" name="event_end_time" class="form-control" :value="time.eventEndTime" readonly />
                    </div>
                    <div class="col-4">
                        <label for="eventBuildingStart" class="form-label">{{translation('event_building_start')}}</label>
                        <input type="text" id="eventBuildingStart" name="event_building_start" class="form-control" :value="time.eventBuildingStart" />
                    </div>
                    <div class="col-2">
                        <label for="eventBuildingStartTime" class="form-label">{{translation('time')}}</label>
                        <input type="time" id="eventBuildingStartTime" name="event_building_start_time" class="form-control" :value="time.eventBuildingStartTime" />
                    </div>
                    <div class="col-4">
                        <label for="eventBuildingEnd" class="form-label">{{translation('event_building_end')}}</label>
                        <input type="text" id="eventBuildingEnd" name="event_building_end" class="form-control" :value="time.eventBuildingEnd" />
                    </div>
                    <div class="col-2">
                        <label for="eventBuildingEndTime" class="form-label">{{translation('time')}}</label>
                        <input type="time" id="eventBuildingEndTime" name="event_building_end_time" class="form-control" :value="time.eventBuildingEndTime" />
                    </div>
                    <div class="col-4">
                        <label for="eventDischargeStart" class="form-label">{{translation('event_discharge_start')}}</label>
                        <input type="text" name="event_discharge_start" id="eventDischargeStart" class="form-control" :value="time.eventDischargeStart" />
                    </div>
                    <div class="col-2">
                        <label for="eventDischargeStartTime" class="form-label">{{translation('time')}}</label>
                        <input type="time" id="eventDischargeStartTime" name="event_discharge_start_time" class="form-control" :value="time.eventDischargeStartTime" />
                    </div>
                    <div class="col-4">
                        <label for="eventDischargeEnd" class="form-label">{{translation('event_discharge_end')}}</label>
                        <input type="text" id="eventDischargeEnd" name="event_discharge_end" class="form-control" :value="time.eventDischargeEnd" />
                    </div>
                    <div class="col-2">
                        <label for="eventDischargeEndTime" class="form-label">{{translation('time')}}</label>
                        <input type="time" id="eventDischargeEndTime" name="event_discharge_end_time" class="form-control" :value="time.eventDischargeEndTime" />
                    </div>
                    <div class="col-4">
                        <label for="offerDueDate" class="form-label">{{translation('offer_deadline_date')}}</label>
                        <input type="text" id="offerDueDate" name="offer_due_date" class="form-control datepicker" value="" />
                    </div>
                    <div class="col-4">
                        <label for="offerValidDate" class="form-label">{{translation('performers_offer_valid_date')}}</label>
                        <input type="text" id="offerValidDate" name="offer_valid_date" class="form-control datepicker" value="" />
                    </div>
                    <div class="col-4">
                        <label for="visitorNumber" class="form-label">{{translation('number_of_participants')}}</label>
                        <input type="number" id="visitorNumber" name="visitor_number" class="form-control" :value="participant" />
                    </div>
                </div>
                <div class="col-12 row g-3" id="offerRequestSpecificFields">
                    <keep-alive>
                        <PerformerCreateOffer v-if="requestType === 1" :performers="performers"/>
                    </keep-alive>
                    <keep-alive>
                        <CateringCreateOffer  v-if="requestType === 2" :caterings="caterings" />
                    </keep-alive>
                    <keep-alive>
                        <TechnologyCreateOffer v-if="requestType === 3" :technologies="technologies" />
                    </keep-alive>
                    <keep-alive>
                        <LogisticsCreateOffer v-if="requestType === 4"/>
                    </keep-alive>
                    <keep-alive>
                        <FurnishingDecorationCreateOffer v-if="requestType === 5" />
                    </keep-alive>
                    <keep-alive>
                        <VenueCreateOffer v-if="requestType === 6" :venues="venues" />
                    </keep-alive>
                    <thirdPartySelection :offerId="requestType"   />
                </div>
            </div>
        </form>
    </div>
    <div id="sendOffersTable" v-if="activeTab === 'send_offers'">
        <form autocomplete="off" id="sendOfferRequestsForm">
            <offersTable :offerStatus="1" :refreshList="refreshListTrigger"/>
        </form>
    </div>
    <div id="sentOffersTable" v-if="activeTab === 'sent_offers'">
        <offersTable :offerStatus="2" @switchTab="changeActiveTab" />
    </div>
    <div id="receivedOffersTable" v-if="activeTab === 'received_offers'">
        <offersTable :offerStatus="3" @switchTab="changeActiveTab" />
    </div>
    <div id="declinedOffersTable" v-if="activeTab === 'declined_offers'">
        <offersTable :offerStatus="4" />
    </div>
    <div id="selectedOffersTable" v-if="activeTab === 'selected_offers'">
        <offersTable :offerStatus="5"/>
    </div>
</template>

<script>

import PerformerCreateOffer from "./PerformerCreateOffer";
import CateringCreateOffer from "./CateringCreateOffer";
import TechnologyCreateOffer from "./TechnologyCreateOffer";
import LogisticsCreateOffer from "./LogisticsCreateOffer";
import FurnishingDecorationCreateOffer from "./FurnishingDecorationCreateOffer";
import VenueCreateOffer from "./VenueCreateOffer";
import thirdPartySelection from './ThirdPartySelection';
import offersTable from "./OffersTable";

var emitter = require('tiny-emitter/instance');


export default {
    name: "offerscontent",
    props: [
        'offerData',
        'timeDetails',
        'participantNumber',
        'projectCaterings',
        'projectPerformers',
        'projectTechnologies',
        'projectVenues'
    ],
    data() {
        return {
            offerTypes: [],
            activeTab : 'create_offers',
            activeTabLeft : 'Performers',
            activeTabId : 1,
            refreshListTrigger: 0,
            time:[],
            participant : '',
            caterings: [],
            performers: [],
            technologies: [],
            requestType: 0,
            currentRequestType: 1,
            offerRequestTypeOptions: [
                {
                    value: 1, text: "performers"
                },
                {
                    value: 2, text: "catering"
                },
                {
                    value: 3, text: "technology"
                },
                {
                    value: 4, text: "logistics"
                },
                {
                    value: 5, text: "furnishing_decoration"
                },
                {
                    value: 6, text: "venue"
                },
            ],
            venues: []
        };
    },
    watch: {
        requestType: function(val, oldVal) {

            let url = "";
            this.currentRequestType = val;

            switch(val) {
                case 1:
                    url = "store-performer-offer";
                    break;
                case 2:
                    url = "store-catering-offer";
                    break;
                case 3:
                    url = "store-technologies-offer";
                    break;
                case 4:
                    url = "store-logistics-offer";
                    break;
                case 5:
                    url = "store-furnishing-decoration-offer";
                    break;
                case 6:
                    url = "store-venue-offer";
                    break;
            }

            document.getElementById("offerFooterButton").setAttribute("data-url", url);
        }
    },
    components:{
        thirdPartySelection,
        offersTable,
        PerformerCreateOffer,
        CateringCreateOffer,
        TechnologyCreateOffer,
        LogisticsCreateOffer,
        FurnishingDecorationCreateOffer,
        VenueCreateOffer
    },
    mounted() {
        this.offerTypes = this.offerData;
        this.participant = this.participantNumber;
        this.time = this.timeDetails;
        this.caterings = this.projectCaterings;
        this.performers = this.projectPerformers;
        this.technologies = this.projectTechnologies;
        this.venues = this.projectVenues;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if(Object.keys(params).length){
            this.activeTabLeft = params.offer_type;
            this.activeTabId = params.offer_type_id;
            this.activeTab = 'received_offers';
        }

        if(this.activeTab === 'create_offers'){
            document.getElementById('offerFooterButton').classList.remove('d-none');
            this.requestType = 1;
        }
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },

        OfferSent() {
            var form = document.getElementById("sendOfferRequestsForm");

            const axiosRequest = postFormAxios('send-offer', form, null);

            axiosRequest.then((data) => {
                if (data.success == true) {
                    //toast.success(data.message);
                    toastMessage(data.message, 'success');

                    this.refreshListTrigger++;
                }
            });
        },

        createOfferEdit(offer){
            this.activeTab = 'create_offers';
        },

        manageFooterButton(){

            document.getElementById('offerFooterButton').classList.add('d-none');
            document.getElementById('sendOffersButton').classList.add('d-none');

            if(this.activeTab === 'create_offers'){
                document.getElementById('offerFooterButton').classList.remove('d-none');
            }

            if(this.activeTab === 'send_offers'){
                document.getElementById('sendOffersButton').classList.remove('d-none');
            }
        },
        changeActiveTab(tab) {
            this.activeTab = tab;
        },

        changeRequestType() {
            if (!confirm(trans('change_offer_request_type_confirm'))) {
                this.requestType = this.currentRequestType;
            }
        }
    },
};
</script>
<style scoped>
    #eventStart, #eventEnd {
        pointer-events: none !important;
    }
</style>
