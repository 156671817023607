<template>
    <h2 class="text-primary">{{ translation('event_sub_type_edit') }}</h2>
    <form autocomplete="off" id="eventSubTypeEdit">
    
    <div class="row g-3">
        <div class="col-6">
            <label for="eventTypeId" class="form-label">{{translation('event_type_name')}}</label>
            <select name="event_type_id" id="eventTypeId" class="form-select">
                <option value="">{{translation('select')}}</option>
                <option v-for="data in eventTypeList" :value="data.id" :selected="data.id == subEventId.eventTypeId">
                    {{ data.eventType }}
                </option>
            </select>
        </div>
        <div class="col-6">
            <label for="eventSubTypeName" class="form-label">{{translation('sub_event_name')}}</label>
            <input type="text" id='eventSubTypeName' name="event_sub_type_name" class="form-control" :value="subEventId.eventSubTypeName">
            <input type="hidden" id='eventTypeId' name="event_sub_type_id" class="form-control" :value="subEventId.eventSubTypeId">
        </div>
    </div>
</form>
</template>
<script>

export default {
    name: "EventTypeEdit",
    props: [
        'subEventId'
    ],
    mounted() {
        this.loadData();
        document.getElementById("adminFooterButton").setAttribute("data-url",'edit-event-sub-type');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'eventSubTypeEdit');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },
    data() {
        return {
            eventTypeList:'',
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(){
            var url = 'get-event-types';
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.eventTypeList = data.eventTypeData;
            });          
        }
    }
}
</script>


