<template>
    <venue-type-list        v-if="activePage === 'list'"        @createVenueType="createVenueType" @editVenueType="editVenueType"   />
    <venue-type-create      v-if="activePage === 'create'"        />
    <venue-type-edit        v-if="activePage === 'edit'"        :editData="this.editData" />
</template>
<script>
 import venueTypeList   from  './VenueTypeList';
 import venueTypeCreate   from  './VenueTypeCreate';
 import venueTypeEdit   from  './VenueTypeEdit';
export default {
    name: "VenueTypeBox",
    components: {
      venueTypeList,
      venueTypeCreate,
      venueTypeEdit,
    },
    data() {
        return {
            activePage :'',
            editData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        createVenueType(){
            this.activePage = 'create';
        },
        editVenueType(venueType){
            this.activePage = 'edit';
            this.editData = venueType;
        }
    },
}
</script>


