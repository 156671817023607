<template>
  <div class="row g-3">
    <div class="col-6">
      <label class="form-label">{{ translation('performer_type') }}*</label>
      <select name="performer_type_id" class="form-select" v-model="performerTypeSelected"  id="performerTypeId">
        <option value="">{{translation('select_performer_type')}}</option>
        <option v-for="performerType in performerTypes" :value="performerType.id">
          {{ performerType.name }}
        </option>
      </select>
    </div>
    <div class="col-6">
      <label class="form-label">{{ translation('price_range_quick_choice') }}</label>
      <select name="performer_price_range_id" @change="setMinMaxValue($event)" class="form-select" v-model="priceRangeSelected" id="performerPriceRangeId">
        <option v-for="PriceRange,item in filteredPriceRange" :value="item" :performer_type_id="PriceRange.performer_type_id">
          {{ PriceRange }}
        </option>
      </select>
    </div>
    <div class="col-6">
      <label class="form-label">{{ translation('min_price') }}*</label>
      <input type="number" step="1" class="form-control" id="performerMinPrice" name="performer_min_price" min="0" value="0" placeholder="">
    </div>

    <div class="col-6">
      <label class="form-label">{{ translation('max_price') }}*</label>
      <input type="number" step="1" class="form-control" id="performerMaxPrice" name="performer_max_price" min="0" value="0" placeholder="">
    </div>

    <div class="col-6">
      <label for="performer_duration_id" class="form-label">{{translation('select_program_duration')}}</label>
      <input type="number" name="performer_duration" id="performer_duration_id" min="" class="form-control">
    </div>
    <div class="col-6">
      <label for="program_additional_information" class="form-label">{{translation('program_additional_information')}}</label>
      <input type="text" class="form-control" id="program_additional_information" name="program_additional_information" value="" placeholder="">
    </div>
    <div class="col-4">
        <button type="button" id=""   @click="callParentPerformerCost();" class="btn btn-primary">{{ translation('add')}}</button>
    </div>
  <div class="col-md-12">
    <table class="table-primary w-100">
      <thead>
        <tr>
          <td>{{translation('performer_type')}}</td>
          <td>{{translation('price')}}</td>
          <td>{{translation('duration')}}</td>
          <td>{{translation('additional_information')}}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(table_data, k) in performer_table_array" :key="k">
          <td v-html="table_data.program_type"></td>
          <td v-html="table_data.performer_price_range"></td>
          <td v-html="table_data.performer_duration_text"></td>
          <td v-html="table_data.program_additional_information"></td>
          <td><a href="#" @click="deleteRow(k, table_data)"><span class="badge rounded-pill bg-danger" >{{ translation('delete') }}</span></a></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

</template>

<script>
import Basic from './Basic';
export default {
  name: "EventType",
  props: {
      performerData: {
        type: Object,
        required: true
      },
    },
  data() {
    return {
      performerTypes: [],
      allPriceRange: [],
      filteredPriceRange: [],
      selectedEventType: "",
      performerTypeSelected: "",
      priceRangeSelected: "",
      performerDuration: "",
      performerDurationSelected: "",
      performer_table_array: [],
      performer_cost:0,
      index : 0,
      minPrice : 0,
      maxPrice : 0,
    };
  },
  mounted() {
    this.populateData();
  },

  methods: {
    translation(arg,replace_message){
      return trans(arg,replace_message);
    },

    populateData() {
          this.performerTypes             =   this.performerData.performerTypes;
          this.filteredPriceRange              =   this.performerData.performarPriceRanges;
          this.performerDuration          =   this.performerData.performerDurationList;
          if(this.performerData.active_programs){
            this.setPerformerDataForEditPreview();
          }
    },
    callParentPerformerCost(){
      var index = this.index++;
      var performer_type_id               =   document.getElementById('performerTypeId').value;
      var performer_price_range_id        =   document.getElementById('performerPriceRangeId').value;
      var performer_duration_id           =   document.getElementById('performer_duration_id').value;
      var program_additional_information  =   document.getElementById('program_additional_information').value;

      /* this is performer type data process*/
      if(!performer_type_id || performer_type_id === ''){
        //toast.error(trans('performer_type_required'));
        toastMessage(trans('performer_type_required'), 'error');
        return false;
      }else{
        var performer_type_object   =   document.getElementById("performerTypeId");
        var performer_type_text     =   performer_type_object.options[performer_type_object.selectedIndex].text;
      }

      /* this is performer price range data process*/
      if (performer_price_range_id && performer_price_range_id != '') {
        var performer_price_range_object  =   document.getElementById("performerPriceRangeId");
        var performer_price_range_text    =   performer_price_range_object.options[performer_price_range_object.selectedIndex].text;
        var performer_price_range_value   =   performer_price_range_object.value.split('-');
        var performer_price_range_id      =   performer_price_range_value[0];
        var performer_max_price_range     =   performer_price_range_value[1];
      }

      this.minPrice = document.getElementById('performerMinPrice').value;
      this.maxPrice = document.getElementById('performerMaxPrice').value;

      /* this is performer duration data process*/
      if(!performer_duration_id || performer_duration_id === ''){
        // toast.error(trans('performer_duration_required'));
        toastMessage(trans('performer_duration_required'), 'error');
        return false;
      }else{
        var performer_duration_object   =   document.getElementById("performer_duration_id");
        var performer_duration_text     =   performer_duration_object.value;
      }

      /* process hidden input*/
      var performer_type_hidden_input         =   '<input type="hidden" name="performer_type_id_array[]" value="' + performer_type_id + '">';
      var performer_min_max_price             =   '<input type="hidden" name="performer_price_range_id_array[]" value="' + performer_price_range_id + '"><input type="hidden" name="performer_min_price_array[]" value="' + this.minPrice + '"><input type="hidden" name="performer_max_price_array[]" value="' + this.maxPrice + '">';
      var performer_duration_hidden_input     =   '<input type="hidden" name="performer_duration_id_array[]" value="' + performer_duration_id + '">';
      var performer_additional_hidden_input   =   '<input type="hidden" name="program_additional_information_array[]" value="' + program_additional_information + '">';

      this.performer_table_array.push({
        program_type                    :   performer_type_text + performer_type_hidden_input,
        performer_price_range           :   this.minPrice+ ' - '+this.maxPrice +' € alv.0% ' + performer_min_max_price,
        performer_duration_text         :   performer_duration_text + performer_duration_hidden_input,
        program_additional_information  :   program_additional_information + performer_additional_hidden_input,
        performer_max_price_range       :   this.maxPrice,
        performer_max_price             :   this.maxPrice,
      });

        this.performer_cost +=  parseInt(this.maxPrice);
        document.getElementById('hidden_max_program_price').value = this.performer_cost;
        document.getElementById('total_program_cost').innerText = this.performer_cost;
        Basic.methods.sumGrandTotal();
    },
    deleteRow(index,table_data){
      var idx = this.performer_table_array.indexOf(table_data);
      if (idx > -1) {
          this.performer_table_array.splice(idx, 1);
          /* substract cost for deleted item*/
          if(this.performerData.active_programs){
            var performer_max_price_range  =   table_data.performer_max_price;
             this.performer_cost =  this.performer_cost - performer_max_price_range;
          }else{
            var performer_price_range_object  =   document.getElementById("performerPriceRangeId");
            var performer_price_range_value   =   performer_price_range_object.value.split('-');
            var performer_max_price_range     =   performer_price_range_value[1]
             this.performer_cost =  this.performer_cost - table_data.performer_max_price_range;
          }
          document.getElementById('hidden_max_program_price').value = this.performer_cost;
          document.getElementById('total_program_cost').innerText = this.performer_cost;
          Basic.methods.sumGrandTotal();
      }
    },
    setPerformerDataForEditPreview(){

          for (var i = 0; i < this.performerData.active_programs.length; i++) {
            /* process hidden input*/
            var performer_type_hidden_input         =   '<input type="hidden" name="performer_type_id_array[]" value="' + this.performerData.active_programs[i].performer_type_id + '">';
            var performer_min_max_price             =   '<input type="hidden" name="performer_price_range_id_array[]" value="' + this.performerData.active_programs[i].performer_price_range_id + '"><input type="hidden" name="performer_min_price_array[]" value="' + this.performerData.active_programs[i].program_price_min_range + '"><input type="hidden" name="performer_max_price_array[]" value="' + this.performerData.active_programs[i].program_price_max_range + '">';
            var performer_duration_hidden_input     =   '<input type="hidden" name="performer_duration_id_array[]" value="' + this.performerData.active_programs[i].performer_duration + '">';
            var performer_additional_hidden_input   =   '<input type="hidden" name="program_additional_information_array[]" value="' + this.performerData.active_programs[i].program_additional_information + '">';

            this.performer_cost += this.performerData.active_programs[i].performer_max_price;
            document.getElementById('hidden_max_program_price').value = this.performer_cost;
            document.getElementById('total_program_cost').innerText = this.performer_cost;

            this.performer_table_array.push({
              program_type                    :   this.performerData.active_programs[i].program_type + performer_type_hidden_input,
              performer_price_range           :   this.performerData.active_programs[i].program_price_min_range+' - '+this.performerData.active_programs[i].program_price_max_range+'€ alv.0% '+performer_min_max_price,
              performer_duration_text         :   this.performerData.active_programs[i].performer_duration + performer_duration_hidden_input,
              program_additional_information  :   this.performerData.active_programs[i].program_additional_information + performer_additional_hidden_input,
              performer_max_price             :   this.performerData.active_programs[i].performer_max_price,
            });
          }
    },
    setMinMaxValue(PriceRange){
      var price_range = PriceRange.target.value;
      this.minPrice   = price_range.split('-')[0] == 'upto' ? 0 : price_range.split('-')[0];
      this.maxPrice   = price_range.split('-')[1];
      document.getElementById('performerMinPrice').value = this.minPrice;
      document.getElementById('performerMaxPrice').value = this.maxPrice;
    }
  },
};
</script>
