<template>
    <performer-type-list    v-if="activePage === 'list'"    @createPerformerType="createPerformerType" @editPerformerType="editPerformerType" />
    <performer-type-create  v-if="activePage === 'create'"      />
    <performer-type-edit    v-if="activePage === 'edit'"    :editData="this.editData"/>
</template>
<script>
 import performerTypeList   from  './PerformerTypeList';
 import performerTypeCreate   from  './PerformerTypeCreate';
 import performerTypeEdit   from  './PerformerTypeEdit';
export default {
   
    name: "PerformerTypeBox",
    components: {
      performerTypeList,
      performerTypeCreate,
      performerTypeEdit,
    },
    data() {
        return {
            activePage :'',
            editData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        createPerformerType(){
            this.activePage = 'create';
        },
        editPerformerType(performerType){
            this.activePage = 'edit';
            this.editData = performerType;
        }
    },
}
</script>


