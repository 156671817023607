<template>
    <div class="event_create">
        <a href="" @click.prevent="create()" class="btn btn-primary btn-sm col-2">{{translation('create_performer_type')}}</a>
    </div>
    <h2 class="text-primary">{{ translation('performer_type') }}</h2>
    <table class="table-primary w-100" id="planningProjects">
        <thead>
            <tr>
                <th>{{ translation('performer_type') }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="list,key in performerTypeList">
                <td>{{list.performerTypeName}}</td>
                <td><a href="" @click.prevent="edit(list)">{{translation('edit')}}</a></td>
            </tr>
        </tbody>
    </table>
    <div class="pagination-holder text-center">
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
            </ul>
        </nav> 
    </div>
</template>
<script>

export default {
    name: "PerformerTypeList",
    mounted() {
        this.loadData();
    },
    data() {
        return {
            performerTypeList : [],
            pagination : [],
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url){
            var projectStatus = 1
            var isTemplate = 1
            var url = url ? url : 'get-performer-types';
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.performerTypeList = data.performerTypesData;
                this.pagination = data.pagination;
            });            
        },
        create(){
            this.$emit('createPerformerType')
        },
        edit(list){
            this.$emit('editPerformerType', {...list})
        },
        paginationLink(url){
            this.loadData(url);
        },        
    }
}
</script>


