<template>
    <h3 class="text-primary">{{ translation('edit_event_time_frame_details') }}</h3>
    <form autocomplete="off" id="editTimeFrameDetails">
    <input type="hidden" name="event_time_frame_detail_id" class="form-control" :value="editData.id" >
    
    <div class="row g-3">
        <div class="col-6">
            <label for="eventTimeFrame" class="form-label">{{translation('time_frame_name')}}</label>
            <select name="event_time_frame_id" id="eventTimeFrame" class="form-select">
                <option value="">{{translation('select')}}</option>
                <option v-for="data in eventTimeFrameList" :value="data.eventTimeFrameId" :selected="data.eventTimeFrameName == editData.eventTimeFrame">
                    {{ data.eventTimeFrameName }}
                </option>
            </select>
        </div>
        <div class="col-6">
            <label for="detailsName" class="form-label">{{translation('name')}}</label>
            <input type="text" id='detailsName' name="time_frame_details_name" class="form-control"  :value="editData.name">
        </div>
        <div class="col-6">
            <label for="durationId" class="form-label">{{translation('duration')}}</label>
            <input type="text" id='durationId' name="duration" class="form-control" :value="editData.duration" >
        </div>
        <div class="col-6">
            <label for="description" class="form-label">{{translation('description')}}</label>
            <textarea id="description" name="description" rows="1" class="form-control" :value="editData.description"></textarea>
        </div>
        <div class="col-6">
            <label for="technicalId" class="form-label">{{translation('technical')}}</label>
            <textarea id="technicalId" name="technical" rows="1" class="form-control" :value="editData.technical"></textarea>
        </div>
        <div class="col-6">
            <label for="detailedTask" class="form-label">{{translation('detailed_task')}}</label>
            <textarea id="detailedTask" name="detailed_task" rows="1" class="form-control" :value="editData.detailedTask"></textarea>
        </div>
        <div class="col-6">
            <label for="catering" class="form-label">{{translation('catering')}}</label>
            <textarea id="catering" name="catering" rows="1" class="form-control" :value="editData.catering"></textarea>
        </div>
        <div class="col-6">
            <label for="responsible" class="form-label">{{translation('responsible')}}</label>
            <textarea id="responsible" name="responsible" rows="1" class="form-control" :value="editData.responsible"></textarea>
        </div>

    </div>
</form>
</template>
<script>

export default {
    name: "eventTimeFrameDetailsEdit",
    mounted() {
        this.loadData();
        document.getElementById("adminFooterButton").setAttribute("data-url",'edit-details-event-time-frame');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'editTimeFrameDetails');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },
    props: [
        'editData'
    ],

    data() {
        return {
            eventTimeFrameList:'',
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(){
            var url = 'get-event-time-frames/all';
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.eventTimeFrameList = data.eventTimeFrameList;
            });          
        }
    }
}
</script>


