require('./foundation');
require('./bootstrap');
import { createApp } from 'vue';
import Basic from './components/Basic';
import 'jquery-ui/ui/widgets/datepicker.js';
import Event from './components/EventTypes.vue';
import PerformerPrice from './components/PerformerPrice.vue';
import CateringTypePrice from './components/CateringTypePrice';
import Technology from './components/Technology';
import OffersContent from './components/offers/OffersContent';
import ProjectBox from './components/thirdparties/ProjectBox';
import UserDashboard from './components/users-dashboard/Dashboard';
import TemplateList from './components/TemplateList';
import EventTypeBox from './components/event-types/EventTypeBox';
import EventSubTypeBox from './components/event-sub-types/EventSubTypeBox';
import PerformerTypeBox from './components/performer-types/PerformerTypeBox';
import CateringTypeBox from './components/catering-types/CateringTypeBox';
import PictureTypeBox from './components/picture-types/PictureTypeBox';
import VenueTypeBox from './components/venue-types/VenueTypeBox';
import EventTimeFrameBox from './components/event-time-frame/EventTimeFrameBox';
import UserBox from './components/user/UserBox';
import AdminBox from './components/admins/AdminBox';
import LicenseBox from './components/license/LicenseBox';
import Venue from './components/Venue.vue';

const app = createApp({
  components: {
    Basic,
    Event,
    PerformerPrice,
    CateringTypePrice,
    Technology,
    OffersContent,
    ProjectBox,
    UserDashboard,
    TemplateList,
    EventTypeBox,
    EventSubTypeBox,
    PerformerTypeBox,
    CateringTypeBox,
    PictureTypeBox,
    VenueTypeBox,
    EventTimeFrameBox,
    UserBox,
    AdminBox,
    LicenseBox,
    Venue,
  }
});
app.mount("#app");