<template>
    <h2 class="text-primary">{{ translation('edit_catering_type') }}</h2>
    <form autocomplete="off" id="cateringTypeEdit">
    <div class="row g-3">
        <div class="col-6">
            <label for="cateringTypeName" class="form-label">{{translation('catering_type_name')}}</label>
            <input type="text" id='cateringTypeName' name="catering_type_name" class="form-control" :value="editData.cateringTypeName">
            <input type="hidden" name="catering_type_id" class="form-control" :value="editData.cateringTypeId">
        </div>
    </div>
</form>
</template>
<script>

export default {
    name: "PerformerTypeEdit",
    props: [
        'editData'
    ],
    mounted() {
        document.getElementById("adminFooterButton").setAttribute("data-url",'edit-catering-type');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'cateringTypeEdit');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },
    data() {
        return {
            
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
    }
}
</script>


