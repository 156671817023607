<template>
    <div class="event_create">
    </div>
    <h2 class="text-primary">{{ translation('admin_list') }}</h2>
    <table class="table-primary w-100" id="planningProjects">
        <thead>
            <tr>
                <th>{{ translation('admin') }}</th>
                <th>{{ translation('email') }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="list,key in adminList">
                <td>{{list.name}}</td>
                <td>{{list.email}}</td>
                <td>{{list.businessId}}</td>
                <td>{{list.contactPersonName}}</td>
                <td><a href="" @click.prevent="addPrice(list)">{{translation('add_price')}}</a></td>
            </tr>
        </tbody>
    </table>
    <div class="pagination-holder text-center">
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
            </ul>
        </nav> 
    </div>
</template>
<script>

export default {
    name: "adminList",
    mounted() {
        this.loadData();
    },
    data() {
        return {
            adminList : [],
            pagination : [],
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url){
            var projectStatus = 1
            var isTemplate = 1
            var url = url ? url : 'get-admins';
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.adminList = data.usersDataArray;
                this.pagination = data.pagination;
            });            
        },
        addPrice(list){
            this.$emit('addPrice',{...list})
        },
        paginationLink(url){
            this.loadData(url);
        },        
    }
}
</script>


