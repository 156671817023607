<template>
        <div class="col-2">
            <label for="" class="form-label">{{translation('person_transport')}}</label>
            <div class="form-check">
                <input type="radio" class="form-check-input" id='yes' name="person_transport" value="yes" checked>
                <label class="form-check-label" for="yes"> {{translation('yes')}} </label>
            </div>
            <div class="form-check">
                <input type="radio" class="form-check-input" id='no' name="person_transport" value="no">
                <label class="form-check-label" for="no"> {{translation('no')}} </label>
            </div>

        </div>
        <div class="col-2">
            <label for="transportBudget" class="form-label">{{translation('transport_budget')}}</label>
            <input type="number" id='transportBudget' name="transport_budget" class="form-control" value="">
        </div>
        <div class="col-8">
            <label for="personTransportExplanation" class="form-label">{{translation('person_transport_explanation')}}</label>
            <textarea id='personTransportExplanation' name="person_transport_explanation" class="form-control" rows="1"></textarea>
        </div>
        <div class="col-2">
            <label for="" class="form-label">{{translation('goods_transport')}}</label>
            <div class="form-check">
                <input type="radio" class="form-check-input" id='yes' name="goods_transport" value="yes" checked>
                <label class="form-check-label" for="yes"> {{translation('yes')}} </label>
            </div>
            <div class="form-check">
                <input type="radio" class="form-check-input" id='no' name="goods_transport" value="no">
                <label class="form-check-label" for="no"> {{translation('no')}} </label>
            </div>
        </div>
        <div class="col-2">
            <label for="goodsTransportBudget" class="form-label">{{translation('goods_transport_budget')}}</label>
            <input type="number" id='goodsTransportBudget' name="goods_transport_budget" class="form-control" value="">
        </div>
        <div class="col-8">
            <label for="goodsTransportExplanation" class="form-label">{{translation('goods_transport_explanation')}}</label>
            <textarea id='goodsTransportExplanation' name="goods_transport_explanation" class="form-control" rows="1"></textarea>
        </div>
        <div class="col-12">
            <label for="goodsTransportAdditionalInfo" class="form-label">{{translation('goods_transport_additional_info')}}</label>
            <textarea id='goodsTransportAdditionalInfo' name="goods_transport_additional_info" class="form-control" rows="1"></textarea>
        </div>
        <div class="col-12">
            <label for="details" class="form-label">{{translation('details')}}</label>
            <textarea id="details"  name="details" class="form-control"></textarea>
        </div>
</template>

<script>
/* third party selection modal */
import thirdPartySelection      from './ThirdPartySelection';

export default {
  name: "logistics",
  props: [
   ],
   data() {
    return {
    };
  },
   methods: {
    translation(arg,replace_message){
      return trans(arg,replace_message);
    },
   }

};
</script>
