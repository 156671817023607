<template>
    <div class="row g-3">
        <div class="col-6">
            <label for="venueTypeId" class="form-label">{{ translation('select_venue_type') }}*</label>
            <select id="venueTypeId" name="venue_type_id" class="form-select">
                <option value="">{{ translation('select') }}</option>
                <option :value="venueType.id" v-for="(venueType, index) in venues.venueTypes">{{ venueType.name }}</option>
            </select>
        </div>
        <div class="col-6">
            <label for="venueRentPrice" class="form-label">{{ translation('price_for_venue_rent') }}*</label>
            <input type="hidden" name="venue_rent_price_total" id="venueRentPriceNew" value="0">
            <input type="number" step="1" id="venueRentPrice" name="venue_rent_price" class="form-control" value="" min="0">
        </div>
        <div class="col-4">
            <label for="venueAddressStreet" class="form-label">{{ translation('street') }}</label>
            <input id="venueAddressStreet" type="text" class="form-control" name="venue_address_street" value="">
        </div>
        <div class="col-4">
            <label for="venueAddressPostalCode" class="form-label">{{ translation('venue_postal_code') }}</label>
            <input id="venueAddressPostalCode" type="text" class="form-control" name="venue_address_postal_code" value="">
        </div>
        <div class="col-4">
            <label for="venueAddressCity" class="form-label">{{ translation('city') }}*</label>
            <input id="venueAddressCity" type="text" class="form-control" name="venue_address_city" value="">
        </div>
        <div class="col-12">
            <label for="venueExtraInformation" class="form-label">{{ translation('venue_extra_information') }}</label>
            <textarea id="venueExtraInformation" name="venue_extra_information" class="form-control"></textarea>
        </div>
    </div>
    <h5 class="mt-3">{{ translation('specify_extra_criteria_for_venue') }}</h5>
    <div class="row g-3">
        <div class="col-6" v-for="(value, name) in venues.venueAdditionalCriterions">
            <div class="form-check">
            <input class="form-check-input" :id="name" type="checkbox" name="venue_additional_criteria[]" :value="name">
            <label class="form-check-label" :for="name">
                {{ translation(value) }}
            </label>
        </div>
        </div>
        <div class="col-12">
            <button type="button" class="btn btn-primary" @click="addVenueToTable">{{ translation('add') }}</button>
        </div>
        <div class="col-12">
            <table class="table-primary w-100">
                <thead>
                    <tr>
                        <th>{{ translation('venue_type') }}</th>
                        <th>{{ translation('price') }}</th>
                        <th>{{ translation('street') }}</th>
                        <th>{{ translation('city') }}</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, key) in venuesTableData">
                        <td v-html="value.venueType"></td>
                        <td v-html="value.price"></td>
                        <td v-html="value.street"></td>
                        <td v-html="value.city"></td>
                        <td><a href="#"><span class="badge rounded-pill bg-secondary" @click="showMore(key, value)">{{ translation('more') }}</span></a></td>
                        <td><a href="#"><span class="badge rounded-pill bg-danger" @click="deleteVenueRow(key, value)">{{ translation('delete') }}</span></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal fade" id="venueMoreDetails" tabindex="-1" aria-labelledby="venueMoreDetailsLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="venueMoreDetailsLabel">{{ translation('more') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" id="venueMoreDetailsBody">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ translation('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Basic from './Basic';
    import { Modal } from 'bootstrap';

    export default {
        props: {
            venuesData: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                venues: this.venuesData,
                venuesTableData: [],
                index: 0,
            }
        },
        mounted() {
            this.loadVenuesToTable();
        },
        methods: {
            translation(arg, replace_message) {
                return trans(arg, replace_message);
            },
            loadVenuesToTable() {
                let totalRent = 0;

                if (this.venues.projectVenues && this.venues.projectVenues.length > 0) {
                    for (let projectVenue of this.venues.projectVenues) {
                        let venueTypeInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_type_id]' value='"+ projectVenue.venue_type_id +"'>";
                        let venueRentPriceInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_rent_price]' value='"+ projectVenue.price +"'>";
                        let venueAddressStreetInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_address_street]' value='"+ projectVenue.street +"'>";
                        let venueAddressPostalCodeInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_address_postal_code]' value='"+ projectVenue.postal_code +"'>";
                        let venueAddressCityInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_address_city]' value='"+ projectVenue.city +"'>";
                        let venueExtraInformationInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_extra_information]' value='"+ projectVenue.venue_extra_information +"'>";
                        let venueCriteriaInput = '';

                        if (projectVenue.venue_additional_criteria && projectVenue.venue_additional_criteria.length > 0) {
                            for (let vc of projectVenue.venue_additional_criteria) {
                                venueCriteriaInput += "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_additional_criteria][]' value='"+ vc +"'>";
                            }
                        }

                        this.index++;
                        let street = projectVenue.street && projectVenue.street !== 'null' ? projectVenue.street : '';

                        this.venuesTableData.push({
                            venueType: projectVenue.venue_type_name + venueTypeInput,
                            price: projectVenue.price + venueRentPriceInput,
                            street: street + venueAddressStreetInput + venueAddressPostalCodeInput,
                            city: projectVenue.city + venueAddressCityInput + venueExtraInformationInput + venueCriteriaInput,
                            postalCode: projectVenue.postal_code,
                            info: projectVenue.venue_extra_information,
                            criterias: projectVenue.venue_additional_criteria,
                        });

                        totalRent += projectVenue.price;
                    }
                }

                document.getElementById('venueRentPriceNew').value = totalRent;
            },
            addVenueToTable() {
                let venueTypeId = document.getElementById('venueTypeId').value;
                let venueTypeText = document.getElementById('venueTypeId').options[document.getElementById('venueTypeId').selectedIndex].text;
                let venueRentPrice = document.getElementById('venueRentPrice').value;
                let venueAddressStreet = document.getElementById('venueAddressStreet').value;
                let venueAddressPostalCode = document.getElementById('venueAddressPostalCode').value;
                let venueAddressCity = document.getElementById('venueAddressCity').value;
                let venueExtraInformation = document.getElementById('venueExtraInformation').value;

                if (!venueTypeId || venueTypeId === '') {
                    toastMessage(trans('venue_type_required'), 'error');
                    return false;
                }

                if (!venueRentPrice || venueRentPrice === '') {
                    toastMessage(trans('venue_rent_price_required'), 'error');
                    return false;
                }

                if (!venueAddressCity || venueAddressCity === '') {
                    toastMessage(trans('city_required'), 'error');
                    return false;
                }

                let venueCriterias = [];

                for (let criteria in this.venues.venueAdditionalCriterions) {
                    if (document.getElementById(criteria).checked == true) {
                        venueCriterias.push(criteria);
                    }
                }

                let venueTypeInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_type_id]' value='"+ venueTypeId +"'>";
                let venueRentPriceInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_rent_price]' value='"+ venueRentPrice +"'>";
                let venueAddressStreetInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_address_street]' value='"+ venueAddressStreet +"'>";
                let venueAddressPostalCodeInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_address_postal_code]' value='"+ venueAddressPostalCode +"'>";
                let venueAddressCityInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_address_city]' value='"+ venueAddressCity +"'>";
                let venueExtraInformationInput = "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_extra_information]' value='"+ venueExtraInformation +"'>";
                let venueCriteriaInput = '';

                if (venueCriterias.length > 0) {
                    for (let vc of venueCriterias) {
                        venueCriteriaInput += "<input type='hidden' name='projectVenuesArr["+ this.index +"][venue_additional_criteria][]' value='"+ vc +"'>";
                    }
                }

                this.index++;
                this.venuesTableData.push({
                    venueType: venueTypeText + venueTypeInput,
                    price: venueRentPrice + venueRentPriceInput,
                    street: venueAddressStreet + venueAddressStreetInput + venueAddressPostalCodeInput,
                    city: venueAddressCity + venueAddressCityInput + venueExtraInformationInput + venueCriteriaInput,
                    postalCode: venueAddressPostalCode,
                    info: venueExtraInformation,
                    criterias: venueCriterias,
                });

                let rentPrice = document.getElementById('venueRentPriceNew').value;
                let sumRentPrice = parseInt(rentPrice) + parseInt(venueRentPrice);
                document.getElementById('venueRentPriceNew').value = sumRentPrice;
                Basic.methods.sumGrandTotal();
            },
            deleteVenueRow(key, value) {
                let index = this.venuesTableData.indexOf(value);

                if (index > -1) {
                    this.venuesTableData.splice(index, 1);

                    let rentPrice = document.getElementById('venueRentPriceNew').value;
                    let subRentPrice = parseInt(rentPrice) - parseInt(value.price);
                    document.getElementById('venueRentPriceNew').value = subRentPrice;
                    document.getElementById('max_venue_rent_cost').innerText = subRentPrice;
                    Basic.methods.sumGrandTotal();
                }
            },
            showMore(key, value) {
                let venueMoreModal = new Modal(document.getElementById('venueMoreDetails'));
                let venuePostCode = value.postalCode ? value.postalCode : '';
                let venueExtraInfo = value.info ? value.info : '';
                let postCode = "<p class='mb-1'>"+ this.translation('venue_postal_code') +": "+ venuePostCode +"</p>";
                let extraInfo = "<p class='mb-1'>"+ this.translation('venue_extra_information') +": "+ venueExtraInfo +"</p>";
                let extraCriteriaList = '';

                venueMoreModal.show();

                if (value.criterias && value.criterias.length > 0) {
                    for (let extraCriteria of value.criterias) {
                        extraCriteriaList = extraCriteriaList + "<li>"+ this.translation(extraCriteria) +"</li>";
                    }
                }

                let additionalCriteria = "<p class='mb-1'>"+ this.translation('extra_criteria_for_venue') +":</p>";
                
                if (extraCriteriaList != '') {
                    additionalCriteria = additionalCriteria + "<ul>"+ extraCriteriaList +"</ul>";
                }
                
                document.getElementById('venueMoreDetailsBody').innerHTML = postCode + extraInfo + additionalCriteria;
            }
        }
    }
</script>