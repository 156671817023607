<template>
    <div class="event_create">
        <a href="" @click.prevent="create()" class="btn btn-primary btn-sm col-2">{{translation('create_event_time_frame')}}</a>
        &nbsp
        <a href="" @click.prevent="createDetails()" class="btn btn-primary btn-sm col-2">{{translation('add_time_frame_details')}}</a>
    </div>
    <h2 class="text-primary">{{ translation('event_time_frame') }}</h2>
    <table class="table-primary w-100" id="planningProjects">
        <thead>
            <tr>
                <th>{{ translation('time_frame_name') }}</th>
                <th>{{ translation('event_name') }}</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="list,key in eventTimeFrameList">
                <td>{{list.eventTimeFrameName}}</td>
                <td>{{list.eventType}}</td>
                <td><a href="" @click.prevent="edit(list)">{{translation('edit')}}</a></td>
                <td><a href="" @click.prevent="details(list)">{{translation('details')}}</a></td>
            </tr>
        </tbody>
    </table>
    <div class="pagination-holder text-center">
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
            </ul>
        </nav> 
    </div>
</template>
<script>

export default {
    name: "eventTimeFrameList",
    props: [
        'editData'
    ],
    mounted() {
        this.loadData();
    },
    data() {
        return {
            eventTimeFrameList : [],
            pagination : [],
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url){
            console.log(this.editData);
            var url = url ? url : 'get-event-time-frames';
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.eventTimeFrameList = data.eventTimeFrameList;
                this.pagination = data.pagination;
            });            
        },
        create(){
            this.$emit('createEventTimeFrame')
        },
        edit(list){
            this.$emit('editEventTimeFrame', {...list})
        },
        createDetails(){
            this.$emit('eventTimeFrameDetailsCreate')
        },
        details(list){
            this.$emit('eventTimeFrameDetailsView', {...list})
        },
        paginationLink(url){
            this.loadData(url);
        },        
    }
}
</script>


