<template>
  <div class="row g-3">
      <div class="col-6">
      <label class="form-label"> {{translation('catering_type')}}*</label>
      <select name="catering_type_id" v-model="cateringTypeSelected"  class="form-select" id="cateringTypeId">
        <option value="">{{translation('select_catering_type')}}</option>
        <option v-for="cateringType in cateringTypes" :value="cateringType.id">
          {{ cateringType.name }}
        </option>
      </select>
    </div>
    <div class="col-6">
      <label class="form-label">{{ translation('price_range_quick_choice') }}</label>
      <select name="catering_price_info_id" @change="setMinMaxPrice($event)" v-model="priceRangeSelected" class="form-select" id="cateringPriceInfoId">
        <option v-for="filteredPriceInfo,item in filteredPriceInfos" :value="item">
          {{ filteredPriceInfo }}
        </option>
      </select>
    </div>
    <div class="col-6">
      <label class="form-label">{{ translation('min_price') }}*</label>
      <input type="number" step="1" class="form-control" id="cateringMinPrice" name="catering_min_price" min="0" value="0" placeholder="">
    </div>

    <div class="col-6">
      <label class="form-label">{{ translation('max_price') }}*</label>
      <input type="number" step="1" class="form-control" id="cateringMaxPrice" name="catering_max_price" min="0" value="0" placeholder="">
    </div>
    <div class="col-6">
        <label for="cateringNumberOfPeople" class="form-label">{{ translation('number_of_people')}}</label>
        <input type="number" step="1" class="form-control" id="cateringNumberOfPeople" name="catering_number_of_people" value="0" placeholder="" min="0">
    </div>
    <div class="col-6">
        <label for="catering_additional_information" class="form-label">{{ translation('catering_additional_information') }}</label>
        <input type="text" class="form-control" id="catering_additional_information" name="catering_additional_information" value="">
    </div>
      <div class="col-4">
          <button type="button" id="" @click="addMultipleCateringData();"  class="btn btn-primary">{{ translation('add') }}</button>
      </div>
  <div class="col-md-12">
    <table class="table-primary w-100">
      <thead>
        <tr>
            <th scope="">{{translation('catering_type')}}</th>
            <th scope="">{{translation('price')}}</th>
            <th scope="">{{translation('number_of_people')}}</th>
            <th scope="">{{translation('additional_information')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(table_data, k) in cateringTableArray" :key="k">
          <td v-html="table_data.catering_type"></td>
          <td v-html="table_data.price_range"></td>
          <td v-html="table_data.number_of_people"></td>
          <td v-html="table_data.catering_additional_information"></td>
          <td><a href="#" @click="deleteRow(k, table_data)"><span class="badge rounded-pill bg-danger" >{{ translation('delete') }}</span></a></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

</template>

<script>
import Basic from './Basic';
export default {
  name: "Catering",
  props: {
      cateringData: {
        type: Object,
        required: true
      },
    },
  data() {
    return {
      cateringTypes: [],
      allPriceInfos: [],
      filteredPriceInfos: [],
      selectedEventType: "",
      cateringTypeSelected: "",
      priceRangeSelected: "",
      index : 0,
      cateringTableArray: [],
      cateringCost : 0,
      minPrice : 0,
      maxPrice : 0,
      totalPeople:0,
      totalCateringCost: 0,
      cateringNumberOfPeople:0,
    };
  },
  mounted() {
    this.getCateringTypeAndPriceRange();
  },
  methods: {
    translation(arg,replace_message){
      return trans(arg,replace_message);
    },
    getCateringTypeAndPriceRange() {
          this.cateringTypes                =   this.cateringData.cateringTypes;
          this.filteredPriceInfos                =   this.cateringData.cateringPriceInfos;
          this.cateringNumberOfPeople       =   this.cateringData.participant;
          document.getElementById('cateringNumberOfPeople').value = this.cateringNumberOfPeople ? this.cateringNumberOfPeople : 0;
          if(this.cateringData.active_catering){
            this.setCateringDataForEditPreview();
          }
        this.totalCateringCost = document.getElementById('hidden_max_catering_price').value;
    },
    getCateringPriceRange(cateringTypeId) {
      var cateringTypeId = cateringTypeId.target.value;
      var result = this.allPriceInfos.filter((obj) => {
        return obj.catering_type_id == cateringTypeId;
      });
      this.filteredPriceInfos = result;
    },
    addMultipleCateringData(){
      var index = this.index++;
      var catering_type_id                  =   document.getElementById('cateringTypeId').value;
      var catering_price_info_id            =   document.getElementById('cateringPriceInfoId').value;
      var catering_number_of_people         =   document.getElementById('cateringNumberOfPeople').value;
      var catering_additional_information   =   document.getElementById('catering_additional_information').value;

      /* catering type validation check and data process */
      if(!catering_type_id || catering_type_id === ''){
          // toast.error(trans('catering_type_required'));
          toastMessage(trans("catering_type_required"), "error");
          return false;
      }else{
        var catering_type_object   =   document.getElementById("cateringTypeId");
        var catering_type_text     =   catering_type_object.options[catering_type_object.selectedIndex].text;
      }

      /* catering price information validation check and data process */
      if (catering_price_info_id && catering_price_info_id != '') {
        var catering_price_info_object    =   document.getElementById("cateringPriceInfoId");
        var catering_price_info_text      =   catering_price_info_object.options[catering_price_info_object.selectedIndex].text;
      }

      /* getting catering max input */
      var catering_price_info_parts = document.getElementById("cateringPriceInfoId").value.split('-');
      var catering_price_info_id = 0;
      var catering_max_price = 0;
      var catering_price_type = '';
      if (catering_price_info_parts.length >= 3) {
          catering_price_info_id = catering_price_info_parts[0];
          catering_max_price = catering_price_info_parts[1];
          catering_price_type = catering_price_info_parts[2];
      } else {
          catering_price_info_id = $('#cateringPriceInfoId').val();
      }

      this.minPrice = document.getElementById('cateringMinPrice').value;
      this.maxPrice = document.getElementById('cateringMaxPrice').value;

      /* process hidden input */
      var catering_type_hidden_input              =   '<input type="hidden" name="catering_type_id_array[]" value="'+catering_type_id+'">';
      var catering_price_info_hidden_input        =   '<input type="hidden" name="catering_price_info_id_array[]" value="' + catering_price_info_id + '"><input type="hidden" name="catering_min_price_array[]" value="' + this.minPrice + '"><input type="hidden" name="catering_max_price_array[]" value="' + this.maxPrice + '">';
      var catering_max_price_hidden_input         =   '<input type="hidden" name="catering_price_info_max_price_array[]" value="'+ this.maxPrice +'"><input type="hidden" name="catering_price_info_min_price_array[]" value="'+ this.minPrice +'">';
      var catering_price_type_hidden_input        =   '<input type="hidden" name="catering_price_info_pricetype_array[]" value="' + catering_price_type + '">';
      var catering_number_of_people_hidden_input  =   '<input type="hidden" name="catering_number_of_people_array[]" value="' + catering_number_of_people + '"></td> ';
      var catering_additional_hidden_input        =   '<input type="hidden" name="catering_additional_information_array[]" value="' + catering_additional_information + '">';

      this.cateringTableArray.push({
        catering_type                     :   catering_type_text + catering_type_hidden_input,
        price_range                       :   this.minPrice+ ' - '+this.maxPrice +' € alv.0% ' + catering_price_info_hidden_input + catering_max_price_hidden_input + catering_price_type_hidden_input,
        number_of_people                  :   catering_number_of_people + catering_number_of_people_hidden_input,
        catering_additional_information   :   catering_additional_information + catering_additional_hidden_input,
        catering_min_price                :   this.minPrice,
        catering_max_price                :   this.maxPrice,
        catering_number_of_people         :   catering_number_of_people
      });

      var cateringCost =  parseInt(this.maxPrice);
      var totalPeople = parseInt(catering_number_of_people);
      this.totalCateringCost = parseInt(this.totalCateringCost) + (cateringCost * totalPeople);
      document.getElementById('hidden_max_catering_price').value = parseInt(this.totalCateringCost);
      document.getElementById('total_catering_cost').innerText = parseInt(this.totalCateringCost);
      Basic.methods.sumGrandTotal();


    },
    deleteRow(key,table_data){
      var idx = this.cateringTableArray.indexOf(table_data);
      if (idx > -1) {
          this.cateringTableArray.splice(idx, 1);
          var deletedCost = (parseInt(table_data.catering_max_price) * parseInt(table_data.catering_number_of_people));
          this.totalCateringCost = this.totalCateringCost - deletedCost;
          document.getElementById('hidden_max_catering_price').value = this.totalCateringCost;
          document.getElementById('total_catering_cost').innerText = this.totalCateringCost;
          Basic.methods.sumGrandTotal();
      }
    },
    setCateringDataForEditPreview(){
      for (var i = 0; i < this.cateringData.active_catering.length; i++) {
          var catering_type_hidden_input              =   '<input type="hidden" name="catering_type_id_array[]" value="'+this.cateringData.active_catering[i].catering_type_id+'">';
          var catering_price_info_hidden_input        =   '<input type="hidden" name="catering_price_info_id_array[]" value="' + this.cateringData.active_catering[i].catering_price_info_id + '"><input type="hidden" name="catering_min_price_array[]" value="' + this.cateringData.active_catering[i].catering_price_min_range + '"><input type="hidden" name="catering_max_price_array[]" value="' + this.cateringData.active_catering[i].catering_price_max_range + '">';
          var catering_max_price_hidden_input         =   '<input type="hidden" name="catering_price_info_max_price_array[]" value="'+ this.cateringData.active_catering[i].catering_max_price +'">';
          var catering_price_type_hidden_input        =   '<input type="hidden" name="catering_price_info_pricetype_array[]" value="' + this.cateringData.active_catering[i].catering_price_type + '">';
          var catering_number_of_people_hidden_input  =   '<input type="hidden" name="catering_number_of_people_array[]" value="' + this.cateringData.active_catering[i].number_of_people + '"></td> ';
          var catering_additional_hidden_input        =   '<input type="hidden" name="catering_additional_information_array[]" value="' + this.cateringData.active_catering[i].catering_additional_information + '">';

          var cateringCost =  parseInt(this.cateringData.active_catering[i].catering_max_price);
          var totalPeople = parseInt(this.cateringData.active_catering[i].number_of_people);

          this.cateringTableArray.push({
            catering_type                     :   this.cateringData.active_catering[i].catering_type + catering_type_hidden_input,
            price_range                       :   this.cateringData.active_catering[i].catering_price_min_range+' - '+ this.cateringData.active_catering[i].catering_price_max_range+'€ alv.0% ' + catering_price_info_hidden_input + catering_max_price_hidden_input + catering_price_type_hidden_input,
            number_of_people                  :   this.cateringData.active_catering[i].number_of_people + catering_number_of_people_hidden_input,
            catering_additional_information   :   this.cateringData.active_catering[i].catering_additional_information + catering_additional_hidden_input,
            catering_max_price                :   this.cateringData.active_catering[i].catering_max_price,
            catering_number_of_people         :   this.cateringData.active_catering[i].number_of_people,
          });

        this.totalCateringCost += (cateringCost * totalPeople);
        document.getElementById('hidden_max_catering_price').value = this.totalCateringCost;
        document.getElementById('total_catering_cost').innerText = this.totalCateringCost;
        }
    },
    setMinMaxPrice(price){
      var price = price.target.value;
      this.minPrice   = price.split('-')[0];
      this.maxPrice   = price.split('-')[1];
      document.getElementById('cateringMinPrice').value = this.minPrice;
      document.getElementById('cateringMaxPrice').value = this.maxPrice;
    }
  },
};
</script>
