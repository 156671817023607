<template>
    <userList      v-if="activePage === 'list'" @assignOfferTypes="assignOfferTypes"  />
    <assignOfferTypes      v-if="activePage === 'assign_offer_type'" :editData="this.editData" />
</template>
<script>
 import userList     from  './UserList';
 import assignOfferTypes     from  './AssignOfferTypes';
export default {

    name: "UserBox",
    components: {
      userList,
      assignOfferTypes,
    },
    data() {
        return {
            activePage :'',
            editData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        assignOfferTypes(list){
            this.activePage = 'assign_offer_type';
            this.editData = list;
        }
    },
}
</script>


