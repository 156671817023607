<template>
    <h2 class="text-primary">{{ translation('edit_venue_type') }}</h2>
    <form autocomplete="off" id="venueTypeEdit">
    <div class="row g-3">
        <div class="col-6">
            <label for="venueTypeName" class="form-label">{{translation('venue_type_name')}}</label>
            <input type="text" id='venueTypeName' name="venue_type_name" class="form-control" :value="editData.venueTypeName">
            <input type="hidden" name="venue_type_id"  :value="editData.venueTypeId">
        </div>
    </div>
</form>
</template>
<script>

export default {
    name: "VenueTypeCreate",
    props: [
        'editData'
    ],
    mounted() {
        document.getElementById("adminFooterButton").setAttribute("data-url",'edit-venue-type');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'venueTypeEdit');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },
    data() {
        return {
            
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        

    }
}
</script>


