<template>
    <div class="col-12">
        <label for="details" class="form-label">{{translation('details')}}</label>
        <textarea id="details" name="details" class="form-control"></textarea>
    </div>
    <div class="col-12">
        <table class="table-primary w-100">
            <thead>
            <tr>
                <th scope="col" style="width: 25px;"></th>
                <td scope="col">{{ translation('venue_type') }}</td>
                <td scope="col">{{ translation('price') }}</td>
                <td scope="col">{{ translation('street') }}</td>
                <td scope="col">{{ translation('postal_code') }}</td>
                <td scope="col">{{ translation('city') }}</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(projectVenue, index) in projectVenues">
                <td scope="row">
                    <div class="form-check">
                        <input type="checkbox" name="venues[]" class="form-check-input list-checkbox" :value="projectVenue.id" checked>
                    </div>
                </td>
                <td>{{ projectVenue.venueTypeName }}</td>
                <td>{{ projectVenue.venueRentPrice }}</td>
                <td>{{ projectVenue.street && projectVenue.street !== 'null' ? projectVenue.street : '' }}</td>
                <td>{{ projectVenue.postalCode && projectVenue.postalCode !== 'null' ? projectVenue.postalCode : '' }}</td>
                <td>{{ projectVenue.city }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

export default {
  name: "venue",
  props: [
      'venues'
  ],
  data() {
      return { 
          projectVenues: this.venues,
      };
  },
  methods: {
      translation(arg,replace_message){
        return trans(arg,replace_message);
      },
  }
};
</script>
