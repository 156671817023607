<template>
    <div class="row">
        <div v-if="name == 'pictureType'" class="mb-3 col-md-3" v-for="(src, id) in pictureSrcs">
            <div :id="id">
                <img :src="src" class="img-thumbnail mood-image" :alt="src">
                <a href="" class="delete-mood-image" @click.prevent="deletePicture(id)">{{ translation('delete') }}</a>
            </div>
        </div>
    </div>
    <button v-if="maxNumberOfFiles && name == 'pictureType'" type="button" :id="'selectFiles' + buttonNumber" :class="[buttonClasses]" :disabled="buttonDisabled">{{ buttonText }}</button>
    <button v-if="name == 'offerRequest'" type="button" :id="'selectFiles' + buttonNumber" :class="[buttonClasses]" :disabled="buttonDisabled">{{ buttonText }}</button>
</template>
<script>
    const Uppy = require("@uppy/core");
    const XHRUpload = require("@uppy/xhr-upload");
    const Dashboard = require("@uppy/dashboard");
    import AwsS3 from '@uppy/aws-s3';
    import Finnish from '@uppy/locales/lib/fi_FI';

    require("@uppy/core/dist/style.css");
    require("@uppy/dashboard/dist/style.css");

    export default {
        props: [
            'name',
            'buttonNumber',
            'buttonText',
            'buttonId',
            'buttonClasses',
            'buttonDisabled',
            'maxFileSize',
            'allowedFileTypes',
            'maxNumberOfFiles',
            'eventTypeId',
            'pictureTypeId',
            'uploadUrl',
            'deleteUrl',
            'pictureSrcs',
            'offerId'
        ],
        mounted() {
            const uppy = new Uppy({
                locale: Finnish,
                restrictions: {
                    maxFileSize: this.maxFileSize,
                    allowedFileTypes: this.allowedFileTypes,
                    maxNumberOfFiles: this.maxNumberOfFiles
                }
            }).use(Dashboard, {
                proudlyDisplayPoweredByUppy: false,
                trigger: "#selectFiles" + this.buttonNumber,
                closeModalOnClickOutside: true,
                closeAfterFinish: true
            }).use(AwsS3, {
                getUploadParameters(file) {
                return fetch('/generate-presigned-url', {
                    method: 'POST',
                    body: JSON.stringify({
                        fileName: file.name,
                        fileType: file.type,
                    }),
                    headers: {
                        accept: 'application/json',
                        'content-type': 'application/json',
                        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').content
                    }
                }).then((response) => {
                    if(response.ok){
                        return response.json();
                    }
                }).then(json => {
                    let preSignedUrl = json.presignedUrl;
                    let s3Path = json.s3Path;

                    return {
                        method: 'PUT',
                        url: preSignedUrl,
                        fields: {},
                        headers: {
                            'Content-Type': file.type,
                        },
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
                }
            });

            uppy.on('upload-success', (file, data) => {
                let formData = new FormData()

                formData.append('s3FileName', file.meta.name);
                formData.append('s3FileType', file.meta.type);
                formData.append('s3FileUrl', data.uploadURL);

                if (this.name == 'pictureType') {
                    formData.append('eventTypeId', this.eventTypeId);
                    formData.append('pictureTypeId', this.pictureTypeId);
                }

                if (this.name == 'offerRequest') {
                    formData.append('offerId', this.offerId);
                }

                let request = postWithAxios(this.uploadUrl, formData);
                let self = this;

                request.then(function (response) {
                    if (response && response.data && response.data.success && self.name == 'pictureType') {
                        self.pictureSrcs[response.data.file_id] = response.data.src;

                        if (self.pictureSrcs.length  >= 200) {
                            self.$emit('changeMaxNumberOfFiles', 0);
                            self.buttonDisabled = false;
                        } else {
                            if (self.maxNumberOfFiles > 0) {
                                self.$emit('changeMaxNumberOfFiles', self.maxNumberOfFiles - 1);
                            }
                        }

                        toastMessage(trans('picture_added'), 'success');
                    }

                    if (response && response.data && response.data.success && self.name == 'offerRequest') {
                        self.$emit('attachmentFileId', response.data.file_id);
                        self.$emit('attachmentName', response.data.file_name);
                        toastMessage(trans('attachment_added'), 'success');
                    }

                    uppy.reset();
                });
            });

            uppy.on("complete", result => {
                uppy.reset();
            });
        },
        methods: {
            translation(arg,replace_message) {
                return trans(arg,replace_message);
            },
            deletePicture(id) {
                if (confirm(trans('confirm_picture_delete'))) {
                    let request = postWithAxios(this.deleteUrl, {eventTypeId: this.eventTypeId, pictureTypeId: this.pictureTypeId, id: id});
                    let self = this;

                    request.then(function (response) {
                        toastMessage(trans('picture_deleted'), 'success');
                        delete self.pictureSrcs[response.data.file_id];
                        self.$emit('changeMaxNumberOfFiles', self.maxNumberOfFiles + 1);
                    });
                }
            }
        }
    }
</script>
