<template>
    <div class="col-lg-6 col-md-12 mb-3 pr-2">
        <div class="dashboard-box bg-white blue-rounded-border p-3" id="receivedOffers">
            <h2 class="text-primary">{{ translation('new_received_offer_requests') }}</h2>
            <table class="table-primary w-100" id="">
                <thead>
                    <tr>
                        <th>{{ translation('name_of_event') }}</th>
                        <th>{{ translation('organization') }}</th>
                        <th>{{ translation('due') }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list,key in offerList">
                        <td>{{list.eventName}}</td>
                        <td>{{list.customer}}</td>
                        <td>{{list.dueDate}}</td>
                           <!--  <td><a :href="'offer-details/'+list.uuId" >View Details</a></td> -->
                        <td><a href="" @click.prevent="showModal(list.uuId)" >{{ translation('view') }}</a></td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-holder text-center pagination-placement">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center">
                        <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="modal fade" id="thirdPartyOfferDetails" tabindex="-1" aria-labelledby="thirdPartyOfferDetailsLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="thirdPartyOfferDetailsLabel"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="offerDetailsModelBody">
                    </div>
                    <file-upload @attachmentFileId="updateAttachmentFileId" @attachmentName="updateAttachmentName" name="offerRequest" :offerId="offerId" :buttonNumber="1" :buttonText="buttonText" :buttonClasses="buttonClasses" :buttonDisabled="buttonDisabled" maxNumberOfFiles="1" :allowedFileTypes="this.allowedFileTypes" uploadUrl="add-attachment" />
                    <div v-if="attachmentFileId && attachmentName" class="mt-3">
                        <h5>{{ translation('attachment') }}</h5>
                        <a :href="href">{{ attachmentName }}</a>
                        <a href="" class="btn btn-sm btn-danger ml-3" @click.prevent="deleteAttachment(this.attachmentFileId)">{{ translation('delete') }}</a>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" id="accept" @click="offerActionAccept('accept')">{{ translation('answer') }}</button>
                    <button type="button" class="btn btn-danger" id="decline" @click="offerActionDecline('decline')">{{ translation('decline') }}</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ translation('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import FileUpload from '../FileUpload.vue';

export default {
    name: "ReceivedOffers",
    components: {
        FileUpload
    },
    mounted() {
      this.loadData();
    },
    data() {
        return {
            offerList : [],
            pagination : [],
            offerId: null,
            buttonText: trans('upload_attachment'),
            buttonClasses: 'btn btn-secondary mt-3',
            buttonDisabled: false,
            allowedFileTypes: ['image/*', 'application/pdf'],
            attachmentFileId: null,
            attachmentName: '',
            href: '',
        };
    },
   methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url){
            var offerStatus = 2 // 2 = send offers
            var url = url ? url : 'get-third-party-offer-request/'+offerStatus;
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.offerList = data.data.offersContent;
                this.pagination = data.data.pagination;
            });
        },
        paginationLink(url){
            this.loadData(url);
        },
        showModal(offerId){
            document.getElementById('accept').classList.remove("d-none");
            document.getElementById('decline').classList.remove("d-none");
            var url = 'offer-details/'+offerId;
            const self = this;
            var axiosRequest = getWithAxios(url);
            axiosRequest.then((data) => {
                if(data.success == true){
                    self.offerId = data.offerId;
                    self.attachmentFileId = data.fileId;
                    self.attachmentName = data.fileName;
                    self.href = '/download-attachment/' + data.fileId;
                    document.getElementById('offerDetailsModelBody').innerHTML = data.html;
                    document.getElementById("accept").setAttribute("data-id", data.offerId);
                    document.getElementById("decline").setAttribute("data-id", data.offerId);
                    document.getElementById('thirdPartyOfferDetailsLabel').innerHTML = data.eventName;
                    var myModal = new Modal(document.getElementById('thirdPartyOfferDetails'));
                    myModal.show();
                }
            });

        },
        offerActionAccept(actionType){
            var offerId = document.getElementById(actionType).getAttribute("data-id");
            var url = '/thirdparty-offer-action';
            var additionalData = {
                'offerId':offerId,
                'actionType':actionType,
            };
            var form = document.querySelector('#offerAccept');
            const axiosRequest = postFormAxios(url, form, additionalData);
            axiosRequest.then((data) => {
               // toast.success(data.message);
                toastMessage(data.message, 'success');
                this.$emit('loadSection');
                this.loadData();
                document.getElementById('accept').classList.add("d-none");
                document.getElementById('decline').classList.add("d-none");
            });

        },
        offerActionDecline(actionType){
            var offerId = document.getElementById(actionType).getAttribute("data-id");
            var url = 'thirdparty-offer-action/'+offerId+'/'+actionType;
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                if (data.success == true) {
                   // toast.success(data.message);
                    toastMessage(data.message, 'success');
                    this.$emit('loadSection');
                    this.loadData();
                    document.getElementById('accept').classList.add("d-none");
                    document.getElementById('decline').classList.add("d-none");
                }
            });
        },
        updateAttachmentFileId(value) {
            this.href = '/download-attachment/' + value;
            this.attachmentFileId = value;
        },
        updateAttachmentName(value) {
            this.attachmentName = value;
        },
        deleteAttachment(id) {
            if (confirm(trans('delete_attachment_confirm'))) {
                let request = postWithAxios('delete-attachment', { id: id });
                let self = this;

                request.then(function (response) {
                    if (response && response.data && response.data.success) {
                        self.attachmentFileId = null;
                        self.attachmentName = '';

                        toastMessage(response.data.message, 'success');
                    }
                });
            }
        }
    }
}
</script>
