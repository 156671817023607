<template>
    <h2 class="text-primary">{{ translation('create_event_type') }}</h2>
    <form autocomplete="off" id="eventTypeCreate">
    <div class="row g-3">
        <div class="col-6">
            <label for="eventTypeName" class="form-label">{{translation('event_type_name')}}</label>
            <input type="text" id='eventTypeName' name="event_type_name" class="form-control" value="">
        </div>
    </div>
</form>
</template>
<script>

export default {
    name: "EventTypeEdit",
    props: [
        'offerId'
    ],
    mounted() {
        document.getElementById("adminFooterButton").setAttribute("data-url",'createOrUpdateEventType');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'eventTypeCreate');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },
    data() {
        return {
            
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        

    }
}
</script>


