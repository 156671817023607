<template>
    <div class="row g-3 col-12">
        <div class="col-6">
            <div class="row g-3">
                <div class="col-12">
                    <p>{{translation('search_and_select_third_party')}}</p>
                </div>
                <div class="col-12">
                    <button class="btn btn-sm btn-primary" @click.prevent="thirdPartySearch='searchModal'">{{translation('search_third_party')}}</button>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row g-3">
                <div class="col-12">
                    <p>{{translation('add_private_third_party')}}</p>
                </div>
                <div class="col">
                    <label for="companyName" class="form-label">{{translation('company_name')}}</label>
                    <input type="text" id="companyName" name="third_party_company_name" class="form-control" />
                </div>
                <div class="col">
                    <label for="emailAddress" class="form-label">{{translation('email')}}</label>
                    <input type="text" id="emailAddress" name="third_party_email" class="form-control" />
                </div>
                <div class="col align-self-end">
                    <button class="btn btn-primary" type="button" @click="addPrivateThirdParty">{{translation('add')}}</button>
                </div>
                <div v-for="(thirdParty, index) in privateThirdParty">
                    <input class="form-control" type="hidden" readonly :name="`private_third_party[${thirdParty.id}][name]`" :value="thirdParty.name" />
                    <input class="form-control" type="hidden" readonly :name="`private_third_party[${thirdParty.id}][email]`" :value="thirdParty.email" />
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-check checkbox-party" id="checkbox-party" v-for="offerType,key in thirdParty">
                <input class="form-check-input" :id="key" type="checkbox" checked name="third_party_id[]" :value="offerType.id" />
                <label class="form-check-label" :for="key"> {{offerType.name}}</label>
            </div>
            <input class="form-check-input" type="hidden" name="third_party_id" disabled />
        </div>
        <thirdPartySearch     v-if="thirdPartySearch === 'searchModal'" @setThirdPartyData="setThirdPartyData" @closeModal="closeModal"  :offerId="offerId"   />
    </div>
</template>

<script>
/* third party search modal */
import thirdPartySearch       from  '../modals/ThirdPartySearch';
export default
{
    name: "thirdPartySelection",
    props: [
        'offerId',
    ],
    components:
    {
        thirdPartySearch,
    },
    data()
    {
        return {
            thirdParty: [],
            privateThirdParty: [],
            thirdPartySearch: '',
            index: 0,
        };
    },
    methods:
    {
        translation(arg, replace_message)
        {
            return trans(arg, replace_message);
        },
        setThirdPartyData(list)
        {
            var isExists = thirdPartyExists(this.thirdParty,list.id);
            if(!isExists){
                this.thirdParty.push(list);
            }else{
                //toastr.error(this.translation('thirdparty_exists'));
                toastMessage(trans("thirdparty_exists"), "error");
            }
        },
        closeModal()
        {
            this.thirdPartySearch = false;
        },
        addPrivateThirdParty(){
            this.index--;
            var companyName = document.getElementById('companyName').value;
            var emailAddress = document.getElementById('emailAddress').value;

            if(companyName != '' && this.ValidateEmail(emailAddress)){
                var privateThirdPartyObject = { id:this.index,name:companyName, email:emailAddress};
                this.privateThirdParty.push(privateThirdPartyObject);
                this.thirdParty.push(privateThirdPartyObject);
                document.getElementById('companyName').value='';
                document.getElementById('emailAddress').value='';
            }else{
                return false;
            }
        },
        ValidateEmail(emailAddress) {
            var mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (emailAddress.match(mailformat)) {
                return true;
            } else {
                //toastr.error('You have entered an invalid email address!');
                toastMessage(trans("entered_invalid_email"), "error");
                return false;
            }
        }
    },
    watch: {
        offerId (newValue, oldValue) {
            if (newValue != oldValue) {
                this.thirdParty = [];
                this.privateThirdParty = [];
            }
        }
    },
}
</script>
