<template>
    <form autocomplete="off" id="adminAddPrice">
    <div class="row g-3">
        <h3 class="text-primary">{{ translation('add_price') }} : {{translation('admin')}} - {{editData.name}}</h3>
        <div class="">
            <input type="hidden" name="admin_id" :value="editData.id">
        </div>
        <div class="" v-for="value in workType">
            <div class="col-3">
                <label class="form-check-label" :for="this.createId(value.name)">{{ translation(value.name)}}</label>
                <input type="number" name="price[]" class="form-control"  :id="this.createId(value.name)" :value="value.price ? value.price : 0" min="0">
                <input type="hidden" name="work_type_id[]" :value="value.id" >   
                <input class="form-check-input" type="hidden" name="price" disabled>             
            </div>
        </div>
    </div>
</form>
</template>
<script>

export default {
    name: "AdminAddPrice",
    props: [
        'editData'
    ],    
    mounted() {
        this.loadData();
        document.getElementById("adminFooterButton").setAttribute("data-url",'admin-add-price');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'adminAddPrice');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },
    data() {
        return {
            workType:'',
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(){
            var url = '/get-initial-data-work-types/'+this.editData.id;
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.workType       = data.workTypesArray;
                console.log(this.workType);
            });          
        },
        createId(string){
            var lowerCase = string.toLowerCase();
            var withOutSpecialCarecter = lowerCase.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
            var camelCase = withOutSpecialCarecter.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
            return camelCase;
        },
        setValue(){
            return 'siam';
        }
    }
}
</script>


