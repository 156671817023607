<template>
    <licenseList    v-if="activePage === 'list'"    @createLicense="createLicense"   @licenseEdit="licenseEdit" />
    <licenseCreate  v-if="activePage === 'create'"    :licesenceData="licesenceData"   />
</template>
<script>
 import licenseList     from  './LicenseList';
 import licenseCreate     from  './LicenseCreate';
export default {

    name: "licenseBox",
    components: {
      licenseList,
      licenseCreate,
    },
    data() {
        return {
            activePage :'',
            editData : '',
            licesenceData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        assignOfferTypes(list){
            this.activePage = 'assign_offer_type';
            this.editData = list;
        },
        createLicense(){
           this.activePage = 'create';
        },
        licenseEdit(list){
           this.activePage = 'create';
           this.licesenceData = list;
        }
    },
}
</script>