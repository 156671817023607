<template>
    <event-time-frame-list              v-if="activePage === 'list'"        @createEventTimeFrame="createEventTimeFrame" @editEventTimeFrame="editEventTimeFrame" @eventTimeFrameDetailsCreate="eventTimeFrameDetailsCreate" @eventTimeFrameDetailsView="eventTimeFrameDetailsView" />
    <event-time-frame-create            v-if="activePage === 'create'"  />
    <event-time-frame-edit              v-if="activePage === 'edit'"        :editData="this.editData " />
    <event-time-frame-details-create    v-if="activePage === 'details-create'"  />
    <event-time-frame-details-list      v-if="activePage === 'details-list-view'"  :editData="this.editData" @timeFrameDetailsEdit="timeFrameDetailsEdit" />
    <event-time-frame-details-edit      v-if="activePage === 'details-edit'"  :editData="this.editData"/>
</template>
<script>
 import eventTimeFrameList     from  './EventTimeFrameList';
 import eventTimeFrameCreate     from  './EventTimeFrameCreate';
 import eventTimeFrameEdit     from  './EventTimeFrameEdit';
 import eventTimeFrameDetailsCreate     from  './EventTimeFrameDetailsCreate';
 import eventTimeFrameDetailsList     from  './EventTimeFrameDetailsList';
 import eventTimeFrameDetailsEdit     from  './EventTimeFrameDetailsEdit';
export default {

    name: "EventTimeFrameBox",
    components: {
      eventTimeFrameList,
      eventTimeFrameCreate,
      eventTimeFrameEdit,
      eventTimeFrameDetailsCreate,
      eventTimeFrameDetailsList,
      eventTimeFrameDetailsEdit,
    },
    data() {
        return {
            activePage :'',
            editData : '',
        }
    },
    mounted() {
       this.activePage = 'list'
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        createEventTimeFrame(){
            this.activePage = 'create';
        },
        editEventTimeFrame(timeFrameData){
            this.activePage = 'edit';
            this.editData = timeFrameData;
        },
        eventTimeFrameDetailsCreate(){
             this.activePage = 'details-create'
        },
        eventTimeFrameDetailsView(timeFrameData){
             this.activePage = 'details-list-view'
             this.editData = timeFrameData;
        },
        timeFrameDetailsEdit(detailsData){
            this.activePage = 'details-edit'
            this.editData = detailsData;
        }
    },
}
</script>


