<template>
    <h3 class="text-primary">{{ translation('event_time_frame_details_list') }}</h3>
    <table class="table-primary w-100" id="planningProjects">
        <thead>
            <tr>
                <th>{{ translation('time_frame_name') }}</th>
                <th>{{ translation('name') }}</th>
                <th>{{ translation('duration') }}</th>
                <th>{{ translation('description') }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="list,key in eventTimeFrameList">
                <td>{{list.eventTimeFrame}}</td>
                <td>{{list.name}}</td>
                <td>{{list.duration}} {{translation('minutes')}}</td>
                <td>{{list.description}}</td>
                <td><a href="" @click.prevent="edit(list)">{{translation('edit')}}</a></td>
            </tr>
        </tbody>
    </table>
    <div class="pagination-holder text-center">
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
            </ul>
        </nav> 
    </div>
</template>
<script>

export default {
    name: "eventTimeFrameDetailsList",
    mounted() {
        this.loadData();
    },
    props: [
        'editData'
    ],    
    data() {
        return {
            eventTimeFrameList : [],
            pagination : [],
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(url){
            var timeFrameDetailId = this.editData.eventTimeFrameId;
            var url = url ? url : 'get-event-time-frame-details-list/'+timeFrameDetailId;
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.eventTimeFrameList = data.eventTimeFrameList;
                this.pagination = data.pagination;
            });            
        },
        create(){
            this.$emit('createEventTimeFrame')
        },
        edit(list){
            this.$emit('timeFrameDetailsEdit', {...list})
        },
        createDetails(){
            this.$emit('eventTimeFrameDetailsCreate')
        },
        details(list){
            console.log(list);
            this.$emit('eventTimeFrameDetailsView', {...list})
        },
        paginationLink(url){
            this.loadData(url);
        },        
    }
}
</script>


