<template>
    <h2 class="text-primary">{{ translation('edit_event_time_frame') }}</h2>
    <form autocomplete="off" id="eventTimeFrameEdit">
    
    <div class="row g-3">
        <div class="col-6">
            <label for="eventTypeId" class="form-label">{{translation('event_type_name')}}</label>
            <select name="event_type_id" id="eventTypeId" class="form-select">
                <option value="">{{translation('select')}}</option>
                <option v-for="data in eventTypeList" :value="data.id" :selected="data.id == editData.eventTypeId">
                    {{ data.eventType }}
                </option>
            </select>
        </div>
        <div class="col-6">
            <label for="timeFrameName" class="form-label">{{translation('time_frame_name')}}</label>
            <input type="text" id='timeFrameName' name="time_frame_name" class="form-control" :value="editData.eventTimeFrameName" >
            <input type="hidden" name="event_time_frame_id" class="form-control" :value="editData.eventTimeFrameId" >
        </div>
    </div>
</form>
</template>
<script>

export default {
    name: "EventTimeFrameCreate",
    mounted() {
        this.loadData();
        document.getElementById("adminFooterButton").setAttribute("data-url",'edit-event-time-frame');
        document.getElementById("adminFooterButton").setAttribute("data-form-id",'eventTimeFrameEdit');
        document.getElementById("adminFooterButton").classList.remove('d-none');
        document.getElementById("adminFooterBackButton").classList.remove('d-none');
    },
    props: [
        'editData'
    ],

    data() {
        return {
            eventTypeList:'',
        };
    },    
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        loadData(){
            var url = 'get-event-types';
            const self = this;
            var axiosRequest = getWithAxios(url);
                axiosRequest.then((data) => {
                this.eventTypeList = data.eventTypeData;
            });          
        }
    }
}
</script>


