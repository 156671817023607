<template>
    <div class="modal fade" id="thirdPartySearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{translation('third_party_list')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body">
                    <!-- <div id="searchThirdParty" v-html="this.thirdPartyList"></div> -->
                    <div class="row">
                        <div class="col-12">
                            <label for="thirdPartySearch" class="form-label">{{translation('search')}}</label>
                            <input type="text" id='thirdPartySearch' name="thirdparty_string" @keyup="setSearchString($event)" class="form-control" >
                            <input type="hidden" id='thirdPartySearchString' name=""  class="form-control" >
                        </div>
                    </div>
                    <br>
                    <br>
                    <div id="searchThirdParty">
                        <table class="table-primary w-100">
                            <thead>
                                <tr>
                                    <th>{{translation('name')}}</th>
                                    <th>{{translation('email')}}</th>
                                    <th>{{translation('offer_type')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="list,key in thirdPartyList">
                                    <td>{{list.name}}</td>
                                    <td>{{list.email}}</td>
                                    <td>{{ translation(list.offerType.toLowerCase()) }}</td>
                                    <td><button class="btn btn-default btn-sm" @click.prevent="selectThirdParty(list)"><i class="fas fa-plus"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination-holder text-center">
                        <nav aria-label="Page navigation">
                            <ul class="pagination justify-content-center">
                                <li v-bind:class="{'page-item':true, 'active':(link.active == true)}" :data-url="link.url"  v-for="link,index in pagination"><a class="page-link"  @click="paginationLink(link.url)" href="#" v-html="link.label"></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">{{translation('close')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import performerCreateOffer   from '../offers/PerformerCreateOffer';
export default {
    name: "ThirdPartySearch",
    props:[
        'offerId'
    ],
    data() {
        return {
            thirdPartyList : [],
             pagination : [],
        }
    },
    mounted() {
        var myModal = new Modal(document.getElementById('thirdPartySearch'));
        myModal.show();
        this.getThirdParty();
    },
    methods: {
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        getThirdParty(url){
            var searchString = document.getElementById('thirdPartySearchString').value;
            var url = url ? url : 'get-thirdparty/'+this.offerId+'?queryParams='+searchString;
            var axiosRequest = getWithAxios(url);
            axiosRequest.then((data) => {
                this.thirdPartyList = data.thirdPartyData;
                this.pagination = data.pagination;
            });
        },
        setSearchString(object){
            document.getElementById('thirdPartySearchString').value = object.target.value;
            this.getThirdParty();
        },
        paginationLink(url){
            this.getThirdParty(url);
        },
        close(){
            this.$emit('closeModal');
        },
        selectThirdParty(list){
             this.$emit('setThirdPartyData',{...list});
        }
    },
}
</script>


