<template>
    <h3>{{ translation('event_tech') }}</h3>
    <p>{{ translation('select_needed_event_tech') }}</p>
    <div v-for="(technologyType,index) in technologyTypes">
        <h5 class="my-3">{{ translation(index)}}</h5>
        <div class="row g-3">
            <div class="col-12" v-for="value in technologyType">
                <div class="form-check">
                    <input :id="this.createId(value)" type="checkbox" class="form-check-input" v-model="selectedTechnologies" :name="'technologies[]'" :value="translation(value)">
                    <label class="form-check-label" :for="this.createId(value)">{{ translation(value)}}</label>
                </div>
            </div>
        </div>
    </div>
    <br>
    <h3>{{ translation('own') }}</h3>
    <div class="row">
        <div class="col-6">
            <button type="button" class="btn btn-sm btn-primary" @click="addOwnTechnology()">{{translation('add_own_technology')}}</button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">
            <table class="table-primary w-100">
                <thead>
                    <tr>
                        <th colspan="2">{{translation('technology_name')}}</th>                       
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(table_data, k) in own_technology_array" :key="k">
                        <td v-html="table_data.input"></td>
                        <td><a href="#" @click="deleteRow(k, table_data)" class="remove-own-technology" ><span class="badge rounded-pill bg-danger" >{{ translation('delete') }}</span></a></td>
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>
    
</template>

<script>


export default {
  name: "Technology",
  props: {
      technologyData: {
        type: Object,
        required: true
      },
    },
  data() {
    return {
      technologyTypes: [],
      own_technology_array : [],
      index : 0,
      selectedTechnologies : [],
    };
  },
  mounted() {
      this.populateData();
  },
  methods: {
     translation(arg,replace_message){
      return trans(arg,replace_message);
    },
    populateData(){
        this.technologyTypes = this.technologyData.technology_data;
        this.selectedTechnologies = this.technologyData.technology_data_by_system;
        this.pupulateUserOwnTechnologyData();
    },
    createName(string){
        var lowerCase = string.toLowerCase();
        var withOutSpecialCarecter = lowerCase.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        var snakeCase = withOutSpecialCarecter.replace(/ /g,"_");
        return snakeCase;
    },
    createId(string){
        var lowerCase = string.toLowerCase();
        var withOutSpecialCarecter = lowerCase.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        var camelCase = withOutSpecialCarecter.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
        return camelCase;
    },
    addOwnTechnology(){
        var index = this.index++;
        this.own_technology_array.push({
            input   :   '<input type="text" id="own_technologies.'+index+'" class="form-control own_technologies" name="own_technologies[]"  maxLength="250">',
        });
    },
    deleteRow(index,table_data){
        var idx = this.own_technology_array.indexOf(table_data);
        if (idx > -1) {
            this.own_technology_array.splice(idx, 1);
        }
    },
    pupulateUserOwnTechnologyData(){
        for (var i = 0; i < this.technologyData.technology_data_by_user.length; i++) {
            this.own_technology_array.push({
                input   :   '<input type="text" id="own_technologies.'+i+'" class="form-control own_technologies" name="own_technologies[]" value="'+this.technologyData.technology_data_by_user[i]+'"  maxLength="250">',
            });
        }

    }
  },
};
</script>