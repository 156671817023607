<template>
<div id="costEstimationCalculator" class="p-3">
  <input type="hidden" name="hidden_max_catering_price" id="hidden_max_catering_price" value="0">
  <input type="hidden" name="hidden_max_program_price" id="hidden_max_program_price" value="0">
    <div class="row g-3">
        <div class="col-6">
            <p class="text-secondary text-uppercase m-0">{{translation('total_budget')}}</p>
            <h4 id="total_budget" class="m-0">{{ totalBudget }}</h4>
        </div>
        <div class="col-6">
            <p  class="text-secondary text-uppercase m-0">{{translation('remaining_budget')}}</p>
            <h4 id="remaining_budget" class="m-0"></h4>
        </div>
    <div class="col-12">
        <p  class="text-secondary text-uppercase m-0">{{translation('design_planning')}}</p>
        <p class="fs-3 m-0">
            <span>{{ designWorkHour }}</span> h, á
            <span>{{ DesignUnitPrice }}</span> € =
            <span>{{ designCost }}</span>
        </p>
    </div>
    <div class="col-12">
        <p  class="text-secondary text-uppercase m-0">{{translation('additional_design_planning')}}</p>
        <div class="row no-gutters">
            <div class="col-3">
                <input type="number" step="1" class="form-control" id="additional_design_hour" @keyup="sumGrandTotal()"  @click="sumGrandTotal()" name="additional_design_hour" value="0" min="0" autocomplete="off" />
            </div>
            <div class="col-8">
                <p class="fs-3 m-0">
                    h, á
                    <span>{{DesignUnitPrice}}</span> € =
                    <span>{{ additionalDesignCost ? additionalDesignCost : 0 }}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="col-12">
        <p  class="text-secondary text-uppercase m-0">{{translation('production')}}</p>
        <p class="fs-3 m-0">
            <span>{{productionHourWork}}</span> h, á
            <span>{{productionWorkUnitPrice}}</span> € =
            <span>{{productionCost}}</span>
        </p>
    </div>
    <div class="col-12">
        <p  class="text-secondary text-uppercase m-0">{{translation('additional_production')}}</p>
        <div class="row no-gutters">
            <div class="col-3">
                <input type="number" step="1" class="form-control" id="additional_production_hour" name="additional_production_hour" value="0" @keyup="sumGrandTotal()" @click="sumGrandTotal()" min="0" autocomplete="off" />
            </div>
            <div class="col-8">
                <p class="fs-3 m-0">
                    h, á
                    <span>{{productionWorkUnitPrice}}</span> € =
                    <span>{{additionalProductionCost ? additionalProductionCost : 0}}</span>
                </p>
            </div>
        </div>
    </div>
        <div class="col-6">
            <p  class="text-secondary text-uppercase m-0">{{translation('online')}}</p>
            <p class="fs-3 m-0">
                <span>{{onlineHour ? onlineHour : 0}}</span> h, á
                <span>{{onlineUnitPrice}}</span> € =
                <span>{{totalOnlineCost ? totalOnlineCost : 0}}</span>
            </p>
        </div>
        <div class="col-6">
            <p  class="text-secondary text-uppercase m-0">{{translation('cost_estimate_for_work_in_total')}}</p>
            <p class="fs-3 m-0">
                <span>{{costWorkInTotal ?costWorkInTotal : 0}}</span>
            </p>
        </div>
        <div class="col-4">
            <p  class="text-secondary text-uppercase m-0">{{translation('program')}}</p>
            <p class="fs-3 m-0">
                <span id="total_program_cost">{{programCost}}</span>
            </p>
        </div>
        <div class="col-4">
            <p  class="text-secondary text-uppercase m-0">{{translation('catering')}}</p>
            <p class="fs-3 m-0">
                <span id="total_catering_cost">{{cateringCost ? cateringCost: 0}}</span>
            </p>
        </div>
        <div class="col-4">
            <p  class="text-secondary text-uppercase m-0">{{translation('venue_rent')}}</p>
            <p class="fs-3 m-0">
                <span id="max_venue_rent_cost">{{ venueRent }}</span>
            </p>
        </div>
        <div class="col-12">
            <p class="text-secondary text-uppercase mb-3">{{translation('total_cost')}}</p>
            <h1 class="text-primary" id="total_cost"></h1>
        </div>
    </div>

  <!-- form input filed -->

  </div>
</template>

<script>


export default {
    name: "ProjectCalculation",
    props: {
      apiData: {
        type: Object,
        required: true
      },
    },
    data() {
        return {
            totalBudget: "",
            remaining_budget: '',
            DesignUnitPrice: "",
            designWorkHour: '',
            designCost: "",
            productionWorkUnitPrice : "",
            productionHourWork : "",
            productionCost : "",
            onlineUnitPrice : "",
            onlineCost : 0,
            costWorkInTotal : 0,
            programCost : "",
            cateringCost : 0,
            venueRent : "",
            grandTotalCost : '',
            additionalDesignCost:'',
            additionalDesignHour : '',
            totalDesignCost : '',
            additionalProductionHour : '',
            additionalProductionCost:'',
            totalProductionCost: '',
            onlineHour: 0,
            totalOnlineCost: 0,
            producerRate : 1.5,
            producerHour : 0,
            participantNumber : 0,
        }
    },
    mounted() {
        this.populateDataEditMode();
    },
    created() {
        // this.sumCostWorkInTotal();
    },
    methods:{
        translation(arg,replace_message){
            return trans(arg,replace_message);
        },
        populateDataEditMode() {
            this.totalBudget                =   this.apiData.total_budget;
            this.remaining_budget           =   (parseInt(this.apiData.total_budget) - parseInt(this.apiData.total_cost));
            this.DesignUnitPrice            =   this.apiData.design_unit_price;
            this.productionWorkUnitPrice    =   this.apiData.production_unit_price;
            this.onlineUnitPrice            =   this.apiData.online_work_unit_price;
            this.costWorkInTotal            =   this.apiData.total_work_cost ;
            this.programCost                =   this.apiData.program ;
            this.cateringCost               =   this.apiData.catering ? this.apiData.catering : 0;
            this.venueRent                  =   this.apiData.venue_rent;
            this.designWorkHour             =   this.apiData.design_hour_work;
            this.productionHourWork         =   this.apiData.production_hour_work;
            this.designCost                 =   (parseInt(this.designWorkHour) * parseInt(this.DesignUnitPrice));
            this.productionCost             =   (parseInt(this.productionHourWork) * parseInt(this.productionWorkUnitPrice));
            this.additionalDesignCost       =   this.apiData.additional_design_cost;
            this.additionalProductionCost   =   this.apiData.additional_production_cost;
            this.onlineHour                 =   this.apiData.online_work_hour;
            this.totalOnlineCost            =   (this.onlineHour * this.onlineUnitPrice);

            document.getElementById('hidden_design_planning_work_hour').value = this.apiData.design_hour_work;
            document.getElementById('hidden_production_work_hour').value = this.apiData.production_hour_work;
            document.getElementById('hidden_online_work_hour').value = this.apiData.online_work_hour;
            document.getElementById('total_cost').innerText = this.apiData.total_cost;
            document.getElementById('remaining_budget').innerText = this.remaining_budget;
            document.getElementById('additional_design_hour').value = this.apiData.design_planning_work_additional_hour ? this.apiData.design_planning_work_additional_hour : 0;
            document.getElementById('additional_production_hour').value = this.apiData.production_work_additional_hour ? this.apiData.production_work_additional_hour : 0;
        },
        sumGrandTotal(){
            console.log("sum");
            this.calculateDesignPrice();
            this.calculateProductionPrice();

            this.totalBudget            =   document.getElementById('eventTotalBudget').value;
            this.designWorkHour         =   document.getElementById('hidden_design_planning_work_hour').value;
            this.DesignUnitPrice        =   document.getElementById('hidden_design_planning_work_unit_price').value;

            this.designCost             =   parseInt(this.DesignUnitPrice) * parseInt(this.designWorkHour);
            this.additionalDesignHour   =   document.getElementById('additional_design_hour').value;
            this.additionalDesignCost   =   (parseInt(this.additionalDesignHour ? this.additionalDesignHour : 0) * this.DesignUnitPrice);
            this.totalDesignCost        =   parseInt( this.designCost) + parseInt(this.additionalDesignCost);

            this.productionHourWork         =   document.getElementById('hidden_production_work_hour').value;
            this.productionWorkUnitPrice    =   document.getElementById('hidden_production_work_unit_price').value;
            this.productionCost             =   parseInt(this.productionWorkUnitPrice) * parseInt(this.productionHourWork);
            this.additionalProductionHour   =   document.getElementById('additional_production_hour').value;
            this.additionalProductionCost   =   (parseInt(this.additionalProductionHour ? this.additionalProductionHour : 0)*parseInt(this.productionWorkUnitPrice));
            this.totalProductionCost        =   (parseInt(this.productionCost) + parseInt(this.additionalProductionCost));

            this.onlineHour         =   this.onlineHourCalculation();
            this.onlineUnitPrice    =   document.getElementById('hidden_online_work_unit_price').value;
            this.totalOnlineCost    =   (parseInt(this.onlineHour) * parseInt(this.onlineUnitPrice));
            this.costWorkInTotal    =   (parseInt(this.totalDesignCost)+ parseInt(this.totalProductionCost) + parseInt(this.totalOnlineCost));

            this.programCost    = document.getElementById('hidden_max_program_price').value;
            this.cateringCost   = document.getElementById('hidden_max_catering_price').value;
            this.venueRent      = document.getElementById('venueRentPriceNew').value;

            document.getElementById('hidden_venue_rent_price').value = this.venueRent;
            document.getElementById('hidden_total_budget').value = this.totalBudget;
            document.getElementById('hidden_design_planning_work_additional_hour').value = this.additionalDesignHour;
            document.getElementById('hidden_production_work_additional_hour').value = this.additionalProductionHour;

            var total = 0;
            if(this.costWorkInTotal){
                total += parseInt(this.costWorkInTotal);
            }
            if(this.programCost){
                total += parseInt(this.programCost);
            }

            if(this.cateringCost){
                total += parseInt(this.cateringCost);
            }
            if(this.venueRent){
                 total += parseInt(this.venueRent);
            }

            this.grandTotalCost =  total;
            this.remaining_budget = (parseInt(this.totalBudget) - parseInt(this.grandTotalCost));
            document.getElementById('total_cost').innerText = parseInt(this.grandTotalCost ? this.grandTotalCost : 0);
            document.getElementById('remaining_budget').innerText = this.remaining_budget ? this.remaining_budget : 0;
        },

        onlineHourCalculation(){
            var participantNumber = document.getElementById('participantNumberId').value;
            var onlineHour = document.getElementById('hidden_online_work_hour').value === 'undefined' ? 0 : document.getElementById('hidden_online_work_hour').value;
            var producerHour = 0;
            var producerRate = 1.5;

            if(participantNumber >= 400){
                producerHour =  parseInt(onlineHour) + (parseInt(onlineHour) * producerRate)
            }

            if(participantNumber >= 1000){
                producerHour =  parseInt(producerHour) + (parseInt(onlineHour) * producerRate);
            }

            if(participantNumber >= 2000){
                var quotient = Math.floor((parseInt(participantNumber)/1000) - 1);
                producerHour =  parseInt(producerHour) + ((parseInt(onlineHour) * producerRate) * quotient);
            }

            return producerHour > 0 ? producerHour : onlineHour;

        },

        calculateDesignPrice() {

            var eventTypeSelect = document.getElementById("eventTypeId");
            var eventType = eventTypeSelect.options[eventTypeSelect.selectedIndex].text;

            var eventSubTypeSelect = document.getElementById("eventSubTypeId");
            var eventSubType = eventSubTypeSelect.options[eventSubTypeSelect.selectedIndex].text;

            var participantType = document.querySelector('input[name="participant_type"]:checked').value;

            var designHoursIndex = eventType.toLowerCase() + "_" + eventSubType.toLowerCase() + "_" + participantType;

            var designHours = {
                "juhla_pikkujoulut_0" : {1000 : 40, 401 : 30, 251 : 24, 151 : 20, 0 : 20},
                "juhla_kesäjuhla_0" : {1000 : 40, 401 : 30, 251 : 24, 151 : 20, 0 : 17},
                "juhla_vuosijuhla_0" : {1000 : 45, 401 : 35, 251 : 35, 151 : 32, 0 : 30},
                "juhla_pikkujoulut_1" : {1000 : 50, 401 : 40, 251 : 30, 151 : 24, 0 : 24},
                "juhla_kesäjuhla_1" : {1000 : 50, 401 : 40, 251 : 30, 151 : 24, 0 : 24},
                "juhla_vuosijuhla_1" : {1000 : 50, 401 : 40, 251 : 30, 151 : 24, 0 : 24},
                "juhla_rakentamisen juhla, harjakaiset_0" : {1000 : 25, 401 : 20, 251 : 17, 151 : 17, 0 : 17},
                "juhla_rakentamisen juhla, vihkiminen_0" : {1000 : 25, 401 : 20, 251 : 17, 151 : 17, 0 : 17},
                "juhla_rakentamisen juhla, peruskiven muuraus_0" : {1000 : 25, 401 : 20, 251 : 17, 151 : 17, 0 : 17},
                "juhla_rakentamisen juhla, harjakaiset_1" : {1000 : 25, 401 : 20, 251 : 17, 151 : 17, 0 : 17},
                "juhla_rakentamisen juhla, vihkiminen_1" : {1000 : 25, 401 : 20, 251 : 17, 151 : 17, 0 : 17},
                "juhla_rakentamisen juhla, peruskiven muuraus_1" : {1000 : 25, 401 : 20, 251 : 17, 151 : 17, 0 : 17},
                "juhla_gaala_0" : {1000 : 45, 401 : 35, 251 : 35, 151 : 32, 0 : 30},
                "juhla_henkilöstöjuhla_0" : {1000 : 40, 401 : 30, 251 : 24, 151 : 20, 0 : 20},
                "juhla_iltajuhla_0" : {1000 : 40, 401 : 30, 251 : 24, 151 : 20, 0 : 20},
                "juhla_gaala_1" : {1000 : 50, 401 : 40, 251 : 30, 151 : 24, 0 : 24},
                "juhla_henkilöstöjuhla_1" : {1000 : 40, 401 : 30, 251 : 24, 151 : 20, 0 : 20},
                "juhla_iltajuhla_1" : {1000 : 50, 401 : 40, 251 : 30, 151 : 24, 0 : 24},
                "virallinen tilaisuus_seminaari_0" : {1000 : 50, 401 : 45, 251 : 40, 151 : 35, 0 : 32},
                "virallinen tilaisuus_seminaari ja iltajuhla_0" : {1000 : 50, 401 : 45, 251 : 40, 151 : 35, 0 : 32},
                "virallinen tilaisuus_kongressi_0" : {1000 : 50, 401 : 45, 251 : 40, 151 : 35, 0 : 32},
                "virallinen tilaisuus_kongressi ja iltajuhla_0" : {1000 : 50, 401 : 45, 251 : 40, 151 : 35, 0 : 32},
                "virallinen tilaisuus_kokous_0" : {1000 : 50, 401 : 45, 251 : 40, 151 : 35, 0 : 32},
                "virallinen tilaisuus_lehdistötilaisuus_0" : {1000 : 50, 401 : 45, 251 : 40, 151 : 35, 0 : 32},
                "virallinen tilaisuus_koulutus_0" : {1000 : 50, 401 : 45, 251 : 40, 151 : 35, 0 : 32},
                "virallinen tilaisuus_henkilöstötilaisuus_0" : {1000 : 50, 401 : 45, 251 : 40, 151 : 35, 0 : 32},
                "virallinen tilaisuus_asiakastilaisuus_0" : {1000 : 50, 401 : 45, 251 : 40, 151 : 35, 0 : 32},
                "virallinen tilaisuus_seminaari_1" : {1000 : 55, 401 : 50, 251 : 44, 151 : 44, 0 : 38},
                "virallinen tilaisuus_seminaari ja iltajuhla_1" : {1000 : 55, 401 : 50, 251 : 44, 151 : 44, 0 : 38},
                "virallinen tilaisuus_kongressi_1" : {1000 : 55, 401 : 50, 251 : 44, 151 : 44, 0 : 38},
                "virallinen tilaisuus_kongressi ja iltajuhla_1" : {1000 : 55, 401 : 50, 251 : 44, 151 : 44, 0 : 38},
                "virallinen tilaisuus_kokous_1" : {1000 : 55, 401 : 50, 251 : 44, 151 : 44, 0 : 38},
                "virallinen tilaisuus_lehdistötilaisuus_1" : {1000 : 50, 401 : 40, 251 : 30, 151 : 24, 0 : 24},
                "virallinen tilaisuus_koulutus_1" : {1000 : 55, 401 : 50, 251 : 44, 151 : 44, 0 : 38},
                "virallinen tilaisuus_henkilöstötilaisuus_1" : {1000 : 55, 401 : 50, 251 : 44, 151 : 44, 0 : 38},
                "virallinen tilaisuus_asiakastilaisuus_1" : {1000 : 50, 401 : 40, 251 : 30, 151 : 24, 0 : 24},
            }

            var hours = 0;
            var participantNumber = document.getElementById('participantNumberId').value;

            if ( designHours[designHoursIndex] !== undefined ) {
                Object.entries(designHours[designHoursIndex]).forEach(([key, value]) => {
                    if ( participantNumber > parseInt(key) ) {
                        hours = value;
                    }
                });

                document.getElementById('hidden_design_planning_work_hour').value = hours;
            }
        },

        calculateProductionPrice() {

            var eventTypeSelect = document.getElementById("eventTypeId");
            var eventType = eventTypeSelect.options[eventTypeSelect.selectedIndex].text;

            var eventSubTypeSelect = document.getElementById("eventSubTypeId");
            var eventSubType = eventSubTypeSelect.options[eventSubTypeSelect.selectedIndex].text;

            var participantType = document.querySelector('input[name="participant_type"]:checked').value;

            var productionHoursIndex = eventType.toLowerCase() + "_" + eventSubType.toLowerCase() + "_" + participantType;

            var productionHours = {
                "juhla_pikkujoulut_0" : { "1000" :35, "401" :30, "251" :27, "151" :25, "0" :20 },
                "juhla_kesäjuhla_0" : { "1000" :30, "400" :28, "251" :24, "151" :24, "0" :20 },
                "juhla_vuosijuhla_0" : { "1000" :45, "400" :40, "251" :35, "151" :35, "0" :35 },
                "juhla_pikkujoulut_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "juhla_kesäjuhla_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "juhla_vuosijuhla_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "juhla_rakentamisen juhla, harjakaiset_0" : { "1000" :35, "400" :35, "251" :35, "151" :35, "0" :35 },
                "juhla_rakentamisen juhla, vihkiminen_0" : { "1000" :35, "400" :35, "251" :35, "151" :35, "0" :35 },
                "juhla_rakentamisen juhla, peruskiven muuraus_0" : { "1000" :35, "400" :35, "251" :35, "151" :35, "0" :35 },
                "juhla_rakentamisen juhla, harjakaiset_1" : { "1000" :35, "400" :35, "251" :35, "151" :35, "0" :35 },
                "juhla_rakentamisen juhla, vihkiminen_1" : { "1000" :35, "400" :35, "251" :35, "151" :35, "0" :35 },
                "juhla_rakentamisen juhla, peruskiven muuraus_1" : { "1000" :35, "400" :35, "251" :35, "151" :35, "0" :35 },
                "juhla_gaala_0" : { "1000" :45, "400" :40, "251" :35, "151" :35, "0" :35 },
                "juhla_henkilöstöjuhla_0" : { "1000" :35, "400" :30, "251" :27, "151" :25, "0" :20 },
                "juhla_iltajuhla_0" : { "1000" :35, "400" :30, "251" :27, "151" :25, "0" :20 },
                "juhla_gaala_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "juhla_henkilöstöjuhla_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "juhla_iltajuhla_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "virallinen tilaisuus_seminaari_0" : { "1000" :45, "400" :43, "251" :40, "151" :35, "0" :35 },
                "virallinen tilaisuus_seminaari ja iltajuhla_0" : { "1000" :45, "400" :43, "251" :40, "151" :35, "0" :35 },
                "virallinen tilaisuus_kongressi_0" : { "1000" :45, "400" :43, "251" :40, "151" :35, "0" :35 },
                "virallinen tilaisuus_kongressi ja iltajuhla_0" : { "1000" :45, "400" :43, "251" :40, "151" :35, "0" :35 },
                "virallinen tilaisuus_kokous_0" : { "1000" :45, "400" :43, "251" :40, "151" :35, "0" :35 },
                "virallinen tilaisuus_lehdistötilaisuus_0" : { "1000" :45, "400" :43, "251" :40, "151" :35, "0" :35 },
                "virallinen tilaisuus_koulutus_0" : { "1000" :45, "400" :43, "251" :40, "151" :35, "0" :35 },
                "virallinen tilaisuus_henkilöstötilaisuus_0" : { "1000" :45, "400" :43, "251" :40, "151" :35, "0" :35 },
                "virallinen tilaisuus_asiakastilaisuus_0" : { "1000" :45, "400" :43, "251" :40, "151" :35, "0" :35 },
                "virallinen tilaisuus_seminaari_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "virallinen tilaisuus_seminaari ja iltajuhla_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "virallinen tilaisuus_kongressi_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "virallinen tilaisuus_kongressi ja iltajuhla_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "virallinen tilaisuus_kokous_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "virallinen tilaisuus_lehdistötilaisuus_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "virallinen tilaisuus_koulutus_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "virallinen tilaisuus_henkilöstötilaisuus_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
                "virallinen tilaisuus_asiakastilaisuus_1" : { "1000" :36, "400" :32, "251" :27, "151" :27, "0" :27 },
            }

            var hours = 0;
            var participantNumber = document.getElementById('participantNumberId').value;

            if ( productionHours[productionHoursIndex] !== undefined ) {
                Object.entries(productionHours[productionHoursIndex]).forEach(([key, value]) => {
                    if (participantNumber > parseInt(key)) {
                        hours = value;
                    }
                });

                document.getElementById('hidden_production_work_hour').value = hours;
            }
        }
    }



};
</script>
